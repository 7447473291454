import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
  transform(size: number): string {
    if (size === 0) return '0B';
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    const index = Math.floor(Math.log(size) / Math.log(1024));
    const formattedSize = (size / Math.pow(1024, index)).toFixed(2);
    return `${formattedSize} ${units[index]}`;
  }
}