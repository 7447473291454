import { EvaluationContract } from '.';
import { GroupSortedChildResultContract } from '.';

/**
 * Report data for 'sorted' report.
 */

export interface SortedReportDataContract {
  calculated: boolean;
  evaluations?: EvaluationContract[];
  categories?: string[];
  children_results?: GroupSortedChildResultContract[];
}
