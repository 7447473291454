import { GameContract } from '../contracts/game.contract';

/**
 * Class representing game.
 */
export class Game {
  public constructor(
    public uuid: string,
    public productCodename: string,
    public shortTitle: string,
    public color: string,
    public source: string,
    public gameUrl: string,
    public description: string,
    public title?: string
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: GameContract): Game {
    const game = new Game(data.uuid, data.product_codename, data.short_title, data.color, data.source, data.game_url, data.description, data.title);

    if (!data.title && data.name) {
      game.title = data.name;
    }

    return game;
  }

  /**
   * Return Test object serialized to basic javascript types.
   */
  public serialize(): GameContract {
    const data: GameContract = {
      uuid: this.uuid,
      name: this.title,
      product_codename: this.productCodename,
      color: this.color,
      short_title: this.shortTitle,
      source: this.source,
      game_url: this.gameUrl,
      description: this.description
    };
    return data;
  }
}
