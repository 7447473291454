import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, IsophiCoreService } from '@isophi/core-legacy';
import { Observable } from 'rxjs';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, protected isophiCoreService: IsophiCoreService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = req.headers;

    headers = headers.set('app-name', this.isophiCoreService.appKeyword).set('app-version', this.isophiCoreService.appVersion);
    if (this.authService.accessToken) {
      headers = headers.set('Authorization', 'Bearer ' + this.authService.accessToken);
    }

    return next.handle(req.clone({ headers }));
  }
}
