<ng-container *transloco="let t">
  <div *ngIf="licenses$ | async as licenses" class="app-header header sticky">
    <div class="container main-container">
      <div class="d-flex align-items-center">
        <div class="dropdown">
          <div data-bs-toggle="dropdown" class="button-wrapper w-100 h-100 d-flex justify-content-center align-items-center">
            <button
              type="button"
              id="main-menu"
              class="btn nav-link icon bg-transparent"
              aria-haspopup="true"
              data-bs-auto-close="true"
              aria-expanded="false"
              #mainDropdownButton
            >
              <i class="fe fe-menu" aria-hidden="true"></i>
            </button>
          </div>
          <div #mainDropdown class="dropdown-menu dropdown-menu-start" aria-labelledby="main-menu">
            <app-navigation></app-navigation>
          </div>
        </div>

        <div class="d-flex order-lg-2 my-auto">
          <div class="mb-0 navbar navbar-dark p-0" id="navbarLogo">
            <div class="d-flex ms-auto">
              <a class="nav-link" [routerLink]="links.homepage()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <img src="assets/img/logo/iSophi.svg" alt="iSophi" />
              </a>
            </div>
          </div>
        </div>

        <div class="d-flex order-lg-2 my-auto">
          <div class="mb-0 navbar navbar-expand-lg responsive-navbar navbar-dark p-0">
            <div class="collapse navbar-collapse" id="navbarNavContent">
              <div class="d-flex ms-auto">
                <ng-container *ngFor="let menu of menuData$ | withLoader | async">
                  <a
                    *ngIf="menu.target.targetType === 'diagnostics_external'; else otherLinks"
                    class="nav-link"
                    href="{{ menu.target.targetType | prepareLinks }}"
                    target="diagApp"
                  >
                    <span class="text-uppercase">{{ menu.target.name }}</span>
                  </a>

                  <ng-template #otherLinks>
                    <a class="nav-link" [routerLink]="menu.target.targetType | prepareLinks" routerLinkActive="active">
                      <span class="text-uppercase">{{ menu.target.name }}</span>

                      <ng-container *ngIf="menu.target.targetType === 'children_and_results'">
                        <a class="nav-link" [routerLink]="links.childArchive()" style="display: none"></a>
                        <a class="nav-link" [routerLink]="links.groupReportProblematicCategories()" style="display: none"></a>
                        <a class="nav-link" [routerLink]="links.groupSortedReport()" style="display: none"></a>
                        <a class="nav-link" [routerLink]="links.groupList()" style="display: none"></a>
                        <a class="nav-link" [routerLink]="links.createChild()" style="display: none"></a>
                      </ng-container>

                      <ng-container *ngIf="menu.target.targetType === 'materials_diagnostic'">
                        <a class="nav-link" [routerLink]="links.materialsDiagnostic()" style="display: none"></a>
                        <a class="nav-link" [routerLink]="links.materialsEducational()" style="display: none"></a>
                      </ng-container>
                    </a>
                  </ng-template>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex order-lg-2 my-auto ms-auto">
          <div class="mb-0 navbar navbar-expand-lg navbar-nav-right responsive-navbar navbar-dark p-0">
            <div class="collapse navbar-collapse" id="navbarSupportedContent-4">
              <div class="d-flex ms-auto align-items-center">
                <a *ngIf="licenses.generalSupport" class="nav-link p-0" [routerLink]="links.manuals()">
                  <i [class]="icons.helpCircle"></i>
                </a>

                <a *ngIf="loggedUser.teacher.isManager()" [routerLink]="links.teacherList()" class="nav-link p-0">
                  <i [class]="icons.settings"></i>
                </a>

                <isophi-language-picker></isophi-language-picker>

                <div class="dropdown header-flags" *ngIf="username">
                  <button
                    type="button"
                    id="manager-menu-profile"
                    class="btn nav-link icon"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src="assets/img/iconsDefault/user.svg" aria-hidden="true" />
                  </button>
                  <div class="dropdown-menu dropdown-menu-end" aria-labelledby="manager-menu-profile">
                    <div class="dropdown-item disabled">{{ username }}</div>
                    <hr />
                    <a [routerLink]="links.changePassword()" class="dropdown-item">{{ t('user/passwordChange') }}</a>
                    <a [routerLink]="loggedUserEditLink" class="dropdown-item">{{ t('shared/editData') }}</a>
                    <a [routerLink]="links.logout()" class="dropdown-item">{{ t('shared/logOut') }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
