import { Component, Input } from '@angular/core';

/**
 * Reusable component for category bar chart such as a cirial chart and cirial comparison.
 */
@Component({
  selector: 'isophi-category-bar-chart',
  templateUrl: './category-bar-chart.component.html',
  styleUrls: ['./category-bar-chart.component.css']
})
export class CategoryBarChartComponent {
  /**
   * category is type of CategoryResult.
   */
  @Input() public category: any;

  @Input() public comparison?: boolean = false;

  @Input() public iconUrl = '';

  @Input() public range?: any;

  @Input() public theme?: 'diag';

  @Input() public showPoints = true;

  @Input() public showPercent = false;

  public score = '+0%';

  public math = Math;

  public calcDistance(index: number): string {
    const factor = 100 / +this.category.maxScore;
    const distance = Math.round(index * factor);
    return `${distance}%`;
  }

  public calcBorderOpacity(index: number, percent: number, maxScore: number): string {
    const indexPercent = (index / maxScore) * 100;

    if (Math.round(indexPercent) < Math.round(percent)) {
      return '0.6';
    }
    return '1';
  }

  public scorePercent(percent: number): number {
    return Math.round(percent);
  }

  // todo (Mohamed): implementation of comparison

  // public comparisonScore(score1, score2): string {
  //   let result = '';
  //   let score = 0;

  //   if (score1 > score2) {
  //     result = 'arrow-down.png';
  //     score = score1 - score2;
  //     this.score = `-${score}%`;
  //   } else if (score1 < score2) {
  //     result = 'arrow-up.jpg';
  //     score = score2 - score1;
  //     this.score = `+${score}%`;
  //   } else {
  //     result = 'equals.png';
  //     score = 0;
  //     this.score = `+${score}%`;
  //   }
  //   return `assets/img/${result}`;
  // }
}
