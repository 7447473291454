import { Category } from "@isophi/core-legacy";
import { MaterialContract } from "../contracts";
import { Document } from "./document.model";


export class Material {
  constructor(
    public uuid: string,
    public title: string,
    public downloadAll: boolean,
    public productId: number,
    public documents: Document[]
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param categories
   */
  public static deserialize(data: MaterialContract, categories: Category[]): Material {
    return new Material(
      data.uuid,
      data.title,
      data.download_all,
      data.product_id,
      data.documents.map((document) => Document.deserialize(document, categories))
    )
  }
}