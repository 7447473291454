/**
 * Main service to manage data required for this (isophi-core) library.
 */
import { Injectable } from '@angular/core';

import { UserRepository } from '../../interface';

@Injectable({
  providedIn: 'root'
})
export class IsophiCoreService {
  protected fields: Map<string, any> = new Map<string, any>();

  protected requiredFields: Array<string> = ['app-version', 'app-keyword', 'client-id', 'app-api'];

  protected optionalFields: Array<string> = ['auth-api', 'user-repository', 'auth-extra-models', 'license-api'];

  constructor() {
    this.setDefaultValues();
  }

  /*
   * ========== Getters for fields ================
   */

  public get appVersion(): string {
    return this.getField('app-version');
  }

  public get appKeyword(): string {
    return this.getField('app-keyword');
  }

  public get clientId(): string {
    return this.getField('client-id');
  }

  public get appApi(): string {
    return this.getField('app-api');
  }

  public get authApi(): string {
    return this.getField('auth-api');
  }

  public get licenseApi(): string {
    return this.getField('license-api');
  }

  public get authExtraModels(): string | null {
    return this.getField('auth-extra-models');
  }

  public get userRepository(): UserRepository {
    return this.getField('user-repository');
  }

  /**
   * Set up required fields.
   *
   * @param appVersion
   * @param appKeyword
   * @param clientId
   * @param appApi
   */
  public setUpRequiredFields(appVersion: string, appKeyword: string, clientId: string, appApi: string) {
    this.fields.set('app-version', appVersion);
    this.fields.set('app-keyword', appKeyword);
    this.fields.set('client-id', clientId);
    this.fields.set('app-api', appApi);
  }

  /**
   * Set field.
   *
   * @param key
   * @param value
   */
  public setField(key: string, value: any) {
    this.fields.set(key, value);
  }

  /**
   * Check if all required settings are set.
   */
  public checkRequiredFields() {
    this.checkFields(this.requiredFields, 'IsophiCoreService');
  }

  /**
   * Check if fields with passed keys are set.
   *
   * @param keys
   * @param callerName
   */
  public checkFields(keys: Array<string>, callerName: string) {
    for (const key of keys) {
      if (!this.fields.has(key)) {
        throw new Error('Required isophi-core library field is missing. For more info see ' + callerName + ' and IsophiCoreService.');
      }
    }
  }

  /**
   * Set default values for fields.
   */
  protected setDefaultValues() {
    this.fields.set('auth-extra-models', null);
  }

  /**
   * Return field from fields, if missing throws error.
   *
   * @param key
   */
  protected getField(key: string) {
    const value = this.fields.get(key);
    if (value === undefined) {
      throw new Error('Missing field "' + key + '" in isophi-core library. See IsophiCoreService for more details.');
    }
    return value;
  }
}
