import { MenuTargetContract } from "./menu-target.contract";

export interface ProductGroupContract {
  name: string;
  title: string;
  logo: string;
  no_results_hint: string;
  no_license_link: string;
  has_results: boolean;
  has_product: boolean;
  promo_description: string;
  short_logo: string;
  show_in_filter: boolean;
  no_results_target: MenuTargetContract
}
