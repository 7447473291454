import { GroupResultContract } from '../contracts';
import { CategoryResult } from './category-result.model';
import { Evaluation } from './evaluation.model';
import { Group } from './group.model';
import { GroupChildResult } from './group-child-result.model';
import { ProblematicCategory } from './problematic-category.models';
import { Test } from './test.model';

/**
 * Calculated report result for group of children.
 */
export class GroupResult {
  public childResults: Array<GroupChildResult> = [];

  public categoryResults: Array<CategoryResult> = [];

  public groups: Array<Group> = [];

  public problematicCategories: Array<ProblematicCategory> = [];

  public constructor(
    public test: Test,
    public maxScore: number,
    public score: number,
    public percent: number,
    public evaluation: Evaluation | null,
    public averageAge: number,
    public problematicPercentLimit: number
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param test
   * @param evaluation
   */
  public static deserialize(data: GroupResultContract, test: Test, evaluation: Evaluation | null): GroupResult {
    return new GroupResult(
      test,
      data.max_score,
      data.score,
      data.percent,
      evaluation,
      data.child_average_age,
      data.problematic_percent_limit
    );
  }

  public addChildResult(childResult: GroupChildResult): void {
    this.childResults.push(childResult);
  }

  public addCategoryResult(categoryResult: CategoryResult): void {
    this.categoryResults.push(categoryResult);
  }

  public addGroup(group: Group): void {
    this.groups.push(group);
  }

  public addProblematicCategory(problematicCategory: ProblematicCategory): void {
    this.problematicCategories.push(problematicCategory);
  }
}
