import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-page-headline',
  templateUrl: './page-headline.component.html',
  styleUrls: ['./page-headline.component.scss']
})
export class PageHeadlineComponent {
  @Input()
  public subTitle: TemplateRef<any> | null = null;
}
