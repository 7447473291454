import { Component, Input } from '@angular/core';

/**
 * Reusable component for percentage.
 */
@Component({
  selector: 'isophi-percentage',
  templateUrl: './percentage.component.html',
  styleUrls: ['./percentage.component.css']
})
export class PercentageComponent {
  @Input() public theme: 'diag' | 'default' = 'default';
}
