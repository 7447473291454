import { Category } from '@isophi/core-legacy';
import { GroupCategoryResultContract } from '../contracts';
import { IndividualCategoryResultContract } from '../contracts';
import { Evaluation } from './evaluation.model';

export class CategoryResult {
  public constructor(
    public category: Category,
    public maxScore: number,
    public score: number,
    public percent: number,
    public evaluation: Evaluation | null
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param category
   * @param evaluation
   */
  public static deserialize(
    data: GroupCategoryResultContract | IndividualCategoryResultContract,
    category: Category,
    evaluation: Evaluation | null
  ): CategoryResult {
    return new CategoryResult(category, data.max_score, data.score, data.percent, evaluation);
  }
}
