/**
 * Service to authenticate user.
 *
 * It requires to set up this field into isophi-core library:
 * - auth-api
 * - client-id
 * - user-repository: Optional for offline login.
 */
import { Injectable } from '@angular/core';

import { User } from '../../models';
import { IStorage } from '../../storages';
import { HttpService } from './http.service';
import { IsophiCoreService } from './isophi-core.service';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedUser: User | null = null;

  public accessToken: string | null = null;

  public accessTokenStorageKey = 'isophiAccessToken';

  constructor(
    protected httpService: HttpService,
    protected securityService: SecurityService,
    protected isophiCoreService: IsophiCoreService
  ) {}

  /**
   * Logout user - clear user data and access token
   */
  public logoutUser(storage: IStorage): void {
    storage.removeOnLogout();
    this.loggedUser = null;
    this.accessToken = null;
  }

  /**
   * Save token to storage.
   *
   * @param storage
   */
  public storeToken(storage: IStorage): void {
    storage.setItem(this.accessTokenStorageKey, this.accessToken, true);
  }

  /**
   * Load token from storage.
   */
  public restoreToken(storage: IStorage): void {
    this.accessToken = storage.getItem(this.accessTokenStorageKey);
  }

  /**
   * Check if token is valid.
   *
   * @returns       True/False if this.accessToken is valid and active.
   */
  public isAccessTokenValid(): Promise<boolean> {
    if (!this.accessToken) return Promise.resolve(false);

    const authUrl = `${this.isophiCoreService.authApi}/introspect/`;
    const postParams = new Map([['token', this.accessToken]]);

    return this.httpService
      .post(authUrl, postParams, this.accessToken)
      .toPromise()
      .then((res) => {
        return res.body.active;
      })
      .catch(() => {
        return false;
      });
  }

  /**
   * Authenticate user by access token saved in this service.
   * If success, it returns true and it saves authenticated User into this.loggedUser,
   * else it returns false.
   */
  public authenticateByAccessToken(): Promise<void> {
    if (!this.accessToken) return Promise.resolve();

    return this.httpService
      .get(this.userUrl(), this.accessToken)
      .toPromise()
      .then((userResponse) => {
        if (userResponse.status !== 200) return Promise.reject('Invalid response code');

        this.loggedUser = User.deserialize(userResponse.body);
      });
  }

  /**
   * Authenticate user.
   * If success, it returns true and it saves authenticated User into this.loggedUser,
   * else it returns false.
   *
   * If onlineLogin is true, it connects to server.
   * Else it does offline login.
   *
   * @param username
   * @param password
   * @param onlineLogin
   */
  public authenticate(username: string, password: string, onlineLogin: boolean = true): Promise<void> {
    if (onlineLogin) {
      return this.serverAuthentication(username, password);
    } else {
      return this.offlineAuthentication(username, password);
    }
  }

  /**
   * Authenticate user offline, it uses Users saved in the Application.
   * If success, it returns authenticated User else it returns null.
   *
   * @param username
   * @param password
   */
  protected offlineAuthentication(username: string, password: string): Promise<void> {
    const userRepository = this.isophiCoreService.userRepository;
    const user = userRepository.getUserByUsername(username, false);

    if (user === undefined) return Promise.reject('User not found in repository.');

    let isPasswordCorrect = false;
    try {
      isPasswordCorrect = this.securityService.checkPassword(password, user.password);
    } catch (e) {
      console.error(e);
    }

    if (isPasswordCorrect) {
      this.loggedUser = user;
      return Promise.resolve();
    } else {
      return Promise.reject('Invalid user credentials.');
    }
  }

  /**
   * Authenticate user in the auth server. If success, it returns authenticated User else it returns null.
   *
   * @param username
   * @param password
   */
  protected serverAuthentication(username: string, password: string): Promise<void> {
    const authUrl = `${this.isophiCoreService.authApi}/token/`;
    const urlParams = new Map<string, any>();
    urlParams.set('grant_type', 'password');
    urlParams.set('client_id', this.isophiCoreService.clientId);
    urlParams.set('username', username);
    urlParams.set('password', password);

    // todo(doubravskytomas): log reason, maybe show specific message to user by error code ?
    return this.httpService
      .post(authUrl, urlParams, null, 'application/x-www-form-urlencoded')
      .toPromise()
      .then((response) => {
        if (response.status !== 200) return Promise.reject('Invalid response code - access token.');

        const data = response.body;
        this.accessToken = data.access_token;
        return this.httpService
          .get(this.userUrl(), this.accessToken)
          .toPromise()
          .then((userResponse) => {
            if (userResponse.status !== 200) return Promise.reject('Invalid response code - user.');

            this.loggedUser = User.deserialize(userResponse.body);
          });
      });
  }

  /**
   * Generate URL for fetching User
   *
   * @protected
   */
  protected userUrl(): string {
    let userUrl = `${this.isophiCoreService.authApi}/logged-user/?extra_fields=password_data,group_string`;
    if (this.isophiCoreService.authExtraModels !== null) {
      userUrl += `&extra_models=${this.isophiCoreService.authExtraModels}`;
    }
    return userUrl;
  }
}
