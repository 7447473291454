import { Component, NgZone, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { WithLoaderPipe } from '@isophi/core-ui';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  public showHeaderAndFooter = false;

  private urlsWithoutHeaderAndFooter: string[] = ['/login', '/login/forgotten-password', '/login/user-logout'];

  private urlsWithUuid: string[] = ['/reports/individual', '/reports/multi', '/kindergarten/teachers'];

  private _subs: Subscription[] = [];

  constructor(private zone: NgZone, private router: Router, protected $gaService: GoogleAnalyticsService, modalConfig: NgbModalConfig) {
    modalConfig.size = 'md';
    this._subs.push(
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          this.showHeaderAndFooter = !this.urlsWithoutHeaderAndFooter.includes(event.url);

          // Remove uuid/id from url
          const loc = document.location;

          if (this.urlsWithUuid.some((url) => loc.pathname.includes(url))) {
            const url = loc.href.split('/');
            url.pop();
            const newUrl = url.join('/');
            this.$gaService.pageView(loc.pathname, document.title, newUrl);
          }
        }
        if (event instanceof NavigationStart) {
          WithLoaderPipe.loadingCount = 0;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach((_sub) => _sub.unsubscribe());
  }
}
