import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Child, Group, ProductGroup } from '@isophi/teachers-shared';
import * as _ from 'lodash';
import { combineLatest,Observable, Subject, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export enum ProductType {
  ALL = 'all',
  DAD_TEST = 'dad_test',
  DAD_TEST_Z = 'dad_test_z',
  QUESTIONNAIRE = 'questionnaire',
  GAME = 'game',
  SMART = 'smart'
}

@Component({
  selector: 'app-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListFilterComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  childrenGroups$: Observable<Group[]>;

  @Input()
  children$: Observable<Child[]>;

  @Input() productGroups$?: Observable<ProductGroup[]>;

  @Input() filterProductActive = true;

  @Input() archiveGroups$?: Observable<string[]>;

  @Output()
  actionFinished = new EventEmitter<boolean>();

  selectedGroups: Group[] = [];

  groupFilter: Subject<Group[]> = new Subject<Group[]>();

  nameFilter: FormControl = new FormControl('');

  filteredChildren$: Observable<Child[]>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  ProductType: typeof ProductType = ProductType;

  selectedSource: ProductType = ProductType.ALL;

  sourceFilter: Subject<ProductType> = new Subject<ProductType>();

  selectedArchiveGroup: string = null;

  productGroup: ProductGroup;

  private _subs: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this._subs.push(
      this.activatedRoute.queryParams.subscribe((val) => {
        if (val.product) {
          this.selectedSource = val.product;
          this.sourceFilter.next(val.product);
          this._subs.push(
            this.productGroups$.subscribe((productGroups) => {
              if (productGroups) {
                this.productGroup = productGroups.filter((product) => product.name === val.product)[0];
                this.cd.markForCheck();
              }
            })
          )
        } else {
          this.selectedSource = ProductType.ALL;
          this.sourceFilter.next(ProductType.ALL);
          this.productGroup = undefined;
          this.cd.markForCheck();
        }
      })
    )
  }

  /**
   * Update filteredChildren$ field, when children$ input field changed.
   */
  ngOnChanges(): void {
    this.clearFilters();
    this.filterChildren();
  }

  ngOnDestroy(): void {
    this._subs.forEach((_sub) => _sub.unsubscribe());
  }

  handleChange(selectedSource: ProductType, productGroup: ProductGroup) {
    this.sourceFilter.next(selectedSource);
    this.productGroup = productGroup;
  }

  filterChildren(): void {
    const nameFilterValue$ = this.nameFilter.valueChanges.pipe(startWith(''));
    const groupFilterValue$ = this.groupFilter.pipe(startWith([]));
    const sourceFilterValue$ = this.sourceFilter.pipe(startWith(ProductType.ALL));

    this.filteredChildren$ = combineLatest([this.children$, nameFilterValue$, groupFilterValue$, sourceFilterValue$]).pipe(
      map(([children, nameFilterValue, groupFilterValue, sourceFilterValue]) => {
        let result = this.filterByFullName(children, nameFilterValue);
        result = this.filterByGroups(result, groupFilterValue);
        result = this.filterBySource(result, sourceFilterValue);
        return result;
      })
    );
  }

  filterByGroups(children: Child[], groups: Group[]): Child[] {
    if (groups.length === 0) return children;

    const groupUuids = groups.map((g) => g.uuid);
    return children.filter((child) => {
      const childGroupUuids = child.groups.map((g) => g.uuid);
      return childGroupUuids.filter((uuid) => groupUuids.includes(uuid)).length !== 0;
    });
  }

  filterByFullName(children: Child[], filterValue: string): Child[] {
    return children.filter((child) => child.fullName.toLowerCase().includes(filterValue.toLowerCase()));
  }

  filterBySource(children: Child[], sourceValue: ProductType): Child[] {
    if (sourceValue === ProductType.ALL) {
      return children;
    } else {
      return children.map((child) => {
        const newChild: Child = _.cloneDeep(child);
        newChild.testResultsCount = newChild.testResultsCount.filter((result) => result.source === sourceValue);

        return newChild;
      });
    }
  }

  refresh(): void {
    this.actionFinished.emit(true)
  }

  /**
   * Reset all filters.
   */
  clearFilters(archive?: boolean): void {
    this.selectedGroups = [];
    this.productGroup = undefined;
    this.groupFilter.next([]);
    this.nameFilter.setValue('');
    this.selectedSource = ProductType.ALL;
    this.sourceFilter.next(ProductType.ALL);

    if (archive) {
      this.selectedArchiveGroup = undefined;
      this.refresh();
    }
    this.cd.detectChanges()
  }
}
