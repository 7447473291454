<footer class="footer" *transloco="let t">
  <hr />

  <div class="container">
    <div class="row align-items-center justify-content-center flex-row-reverse">
      <div class="col-md-12 col-sm-12 mt-3 mt-lg-0 text-center">
        <small class="d-block mb-3"> &copy; 2021 iSophi, {{ t('shared/version') }} {{ version }} {{ envShortcut }} </small>
      </div>
      <isophi-social-networks-icons class="mt-1"></isophi-social-networks-icons>
    </div>
  </div>
</footer>
