import { DateHelper } from '@isophi/core-legacy';
import { CategoryResult } from './category-result.model';
import { Child } from './child.model';
import { CirilCategoryResult } from './ciril-category-result.model';
import { Test } from './test.model';

import { IndividualChildResultContract } from '../contracts/individual-child-result.contract';
import { Game } from './game.model';
import { Questionnaire } from './questionnaire.model';

export class IndividualChildResult {
  public categoryResults: Array<CategoryResult> = [];

  public calculatedCategoryResults: CirilCategoryResult = {
    algorithmicThinking: undefined,
  };

  public constructor(
    public test: Test | Game | Questionnaire | null,
    public child: Child | null,
    public maxScore: number,
    public score: number,
    public percent: number,
    public finishedAt: Date,
    public dataSetUuid: string,
    public source: string,
    public visibleToParent: boolean
  ) {}

  /**
   * Printable title to identify this result.
   */
  public get title(): string {
    return this.finishedAt.toLocaleDateString('cs-CZ');
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param test
   * @param child
   */
  public static deserialize(
    data: IndividualChildResultContract,
    test: Test | Game | Questionnaire | null,
    child: Child | null
  ): IndividualChildResult {
    return new IndividualChildResult(
      test,
      child,
      data.max_score,
      data.score,
      data.percent,
      DateHelper.parse(data.finished_at),
      data.data_set_uuid,
      data.source,
      data.visible_to_parent
    );
  }

  public addCategoryResult(categoryResult: CategoryResult): void {
    this.categoryResults.push(categoryResult);
  }

  public getCalculatedCategoryResults(categoryResult: CategoryResult): void {
    this.calculatedCategoryResults.algorithmicThinking = categoryResult;
  }
}
