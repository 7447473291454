import { Pipe, PipeTransform } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { isObservable, Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

/**
 * Show ngx-spinner loader on pending Observable, use with async pipe.
 *

 * @param value Observable to show loader for
 *
 * @param ignoreNullValue If true, loader will not hide when null value is emitted
 *
 * Example:
 *
 * `<ul *ngFor="let item of items$ | withLoader | async">...</ul>`
 *
 *  Example with `ignoreNullValue`:
 *
 * `<ng-container *ngIf="items$ | withLoader: true | async as items">`
 */
@Pipe({
  name: 'withLoader',
})
export class WithLoaderPipe implements PipeTransform {
  public static loadingCount = 0;

  public constructor(private spinner: NgxSpinnerService) {}

  public transform<T>(value: Observable<T>, ignoreNullValue?: boolean): Observable<T>;
  public transform<T>(value: T, ignoreNull?: boolean): T;

  public transform<T>(value: T | Observable<T>, ignoreNullValue = false): T | Observable<T> {
    if (!isObservable(value)) return value;

    WithLoaderPipe.loadingCount++;
    this.spinner.show();
    return value.pipe(
      finalize(() => this.spinner.hide()),
      tap((value) => {
        if (!(ignoreNullValue && value === null) && (WithLoaderPipe.loadingCount === 0 || --WithLoaderPipe.loadingCount === 0)) {
          this.spinner.hide();
        }
      }),
      catchError((err) => {
        this.spinner.hide();
        return throwError(() => err);
      })
    );
  }
}
