import { SubjectLicensePackBriefDetailContract } from './subject-license-pack-brief-detail.contract';

/**
 * Data with license details for one subject (kindergarten/user/...).
 */
export interface BriefSubjectDetailContract {
  /**
   * License packs.
   *
   * Format:
   * {
   *    "license_title": "Diagnostika 3-4 PLUS",
   *    "toleration_to": "2022-05-26T02:44:32.025015+02:00",
   *    "valid_from": "2022-05-24T22:57:52.025015+02:00",
   *    "valid_to": "2022-05-26T02:44:32.025015+02:00"
   *  }
   */
  license_packs?: SubjectLicensePackBriefDetailContract[];
}
