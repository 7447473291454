import { Injectable } from '@angular/core';
import { AlertType } from '@isophi/core-legacy';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PopUpType } from '../../enums/pop-up-type.enum';
import { PopUpDialogComponent } from './components/pop-up-dialog/pop-up-dialog.component';

// Refactor / rename to service
@Injectable({
  providedIn: 'root',
})
export class PopUpComponent {
  public constructor(private modalService: NgbModal) {}

  /**
   * Open modal component (PopUpDialogComponent).
   *
   * ! Any in returns come from select, need improve.
   *
   * @param title
   * @param text
   * @param popUpType
   * @param alertType
   * @param data
   * @param size
   */
  public openDialog(
    title: string,
    text: string,
    popUpType: string,
    alertType: AlertType | null = null,
    data: Record<string, unknown> | null = null,
    size: 'sm' | 'md' | 'lg' | 'xl' | string = 'md'
  ): Promise<boolean | null | string | any> {
    if (data === null) {
      data = {};
    }
    const defaultData = {
      alertType,
      title,
      text,
      popUpType,
    };
    data = { ...defaultData, ...data };

    const modal: NgbModalRef = this.modalService.open(PopUpDialogComponent, {
      size
    });
    modal.componentInstance.data = data;

    return modal.result.catch(() => null);
  }

  public confirm(
    title: string,
    text: string,
    alertType: AlertType | null = null,
    yesText: string = 'Ano',
    noText: string = 'Ne',
    yesButtonClass: string = 'btn-secondary',
    noButtonClass: string = 'btn-outline-dark',
    size?: 'sm' | 'md' | 'lg' | 'xl' | string,
    titleClass?: string,
    bodyClass?: string,
    footerClass?: string
  ): Promise<boolean | null> {
    const data = { yesText, noText, yesButtonClass, noButtonClass, titleClass, bodyClass, footerClass };
    return this.openDialog(title, text, PopUpType.CONFIRM, alertType, data, size) as Promise<boolean | null>;
  }

  public alert(title: string, text: string, alertType: AlertType | null = null, okText: string = 'Ok'): Promise<boolean | null> {
    return this.openDialog(title, text, PopUpType.ALERT, alertType, { okText }) as Promise<boolean | null>;
  }

  public input(
    title: string,
    label: string,
    placeholder: string,
    defaultValue: string | null = null,
    validationRegex: string | null = null,
    validationMessage: string | null = null,
    okText: string = 'Ok',
    cancelText: string = 'Zrušit',
    okButtonClass: string = 'btn-secondary',
    cancelButtonClass: string = 'btn-outline-dark',
    size?: 'sm' | 'md' | 'lg' | 'xl' | string
  ): Promise<string | null> {
    const data = {
      okText,
      cancelText,
      validationRegex,
      placeholder,
      validationMessage,
      defaultValue,
      okButtonClass,
      cancelButtonClass,
    };
    return this.openDialog(title, label, PopUpType.PROMPT, null, data, size) as Promise<string | null>;
  }

  /**
   *
   * @param title
   * @param label
   * @param options ... Options to select in format [[key, value], [key2, value2], ...]
   * @param optionLabel ... Object property to load option string from options.
   * @param optionValue ... Object property to load option value from options. If null, it returns whole object.
   * @param okText
   * @param cancelText
   * @param okButtonClass
   * @param cancelButtonClass
   * @param size
   */
  public select(
    title: string,
    label: string,
    options: Array<any>,
    optionLabel: string,
    optionValue: string | null = null,
    okText: string = 'Ok',
    cancelText: string = 'Zrušit',
    okButtonClass: string = 'btn-secondary',
    cancelButtonClass: string = 'btn-outline-dark',
    size?: 'sm' | 'md' | 'lg' | 'xl' | string
  ): Promise<any | null> {
    const data = { okText, cancelText, options, optionLabel, optionValue, okButtonClass, cancelButtonClass };
    return this.openDialog(title, label, PopUpType.SELECT, null, data, size) as Promise<any | null>;
  }
}
