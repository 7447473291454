/**
 * One psychological category and children, who have problems in this category.
 */
export interface ProblematicCategoryContract {
  category_uuid: string;
  max_score: number;
  percent: number;
  score: number;
  child_uuids: string[];
}
