<ng-container *transloco="let t">
  <ng-container *ngFor="let menu of menuData$ | withLoader | async; let i = index">
    <ng-container *ngIf="menu.links.length <= 0; else subMenu">
      <a
        *ngIf="menu.target.targetType === 'isophi_management' || menu.target.targetType === 'isophi_teacher'; else otherLinks"
        class="dropdown-item"
        href="{{ menu.target.targetType | prepareLinks }}"
        [target]="menu.target.targetType === 'isophi_management' ? 'management' : 'pap'"
      >
        {{ menu.target.name }}
      </a>
      <ng-template #otherLinks>
        <a class="dropdown-item" [routerLink]="menu.target.targetType | prepareLinks">
          <span>{{ menu.target.name }}</span>
        </a>
      </ng-template>
    </ng-container>

    <ng-template #subMenu>
      <div (click)="preventMenuClose($event)" class="accordion accordion-flush" [id]="'accordionFlush_' + i">
        <div class="accordion-item">
          <h5 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#data_' + i"
              aria-expanded="false"
              [attr.aria-controls]="'data_' + i"
            >
              {{ menu.target.name }}
            </button>
          </h5>
          <div
            [id]="'data_' + i"
            class="accordion-collapse collapse"
            aria-labelledby="flush"
            [attr.data-bs-parent]="'#accordionFlush_' + i"
          >
            <div class="accordion-body">
              <ng-container *ngFor="let subMenuData of menu.links">
                <a
                  *ngIf="
                    subMenuData.targetType === 'auto_evaluation' ||
                      subMenuData.targetType === 'smart_promo' ||
                      subMenuData.targetType === 'isophi_management';
                    else otherLinks
                  "
                  class="dropdown-item"
                  href="{{ subMenuData.targetType | prepareLinks }}"
                  target="_blank"
                >
                  {{ subMenuData.name }}
                </a>

                <ng-template #otherLinks>
                  <a
                    class="dropdown-item"
                    [routerLink]="subMenuData.targetType | prepareLinks"
                    [queryParams]="
                      subMenuData.filteredProductType
                        ? { product: subMenuData.filteredProductType === 'test' ? ProductType.DAD_TEST : subMenuData.filteredProductType }
                        : subMenuData.productId
                        ? { material: subMenuData.productId }
                        : subMenuData.codename
                        ? { codename: subMenuData.codename }
                        : undefined
                    "
                  >
                    {{ subMenuData.name }}
                  </a>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
