import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, Teacher, User } from '@isophi/core-legacy';
import { TeacherContract } from '@isophi/teachers-shared';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { UserDto } from '../dto/user.dto';
import { UserFormErrors } from '../dto/user-form-errors.dto';

@Injectable({
  providedIn: 'root',
})
export class TeacherService {
  public constructor(private authService: AuthService, private http: HttpClient) {}

  /**
   * Create new teacher.
   *
   * @param userDto
   */
  public createTeacher(userDto: UserDto): Observable<any> {
    const url = `${environment.teacherAPI}/teachers/`;

    return this.http.post(url, userDto.serialize()).pipe(catchError(this.handleError));
  }

  /**
   * Update teacher.
   *
   * @param userDto
   * @param teacherId
   */
  public updateTeacher(userDto: UserDto, teacherId: number): Observable<any> {
    const url = `${environment.teacherAPI}/teachers/${teacherId}/`;
    const loggedUser = this.authService.loggedUser;

    return this.http.put(url, userDto.serialize()).pipe(
      tap(() => {
        // todo(doubravskytomas): Updating logged User entity data need refactor:
        if (loggedUser.id === teacherId) {
          loggedUser.firstName = userDto.firstName;
          loggedUser.lastName = userDto.lastName;
          loggedUser.email = userDto.email;
        }
      }),
      catchError(this.handleError)
    );
  }

  /**
   * Remove teacher from a kindergarten.
   *
   * @param user
   */
  public removeTeacher(user: User): Observable<any> {
    const url = `${environment.teacherAPI}/teachers/${user.teacher.id}/`;

    return this.http.delete(url);
  }

  /**
   * Get teacher by id
   *
   * @param id
   */
  public getTeacher(id: number): Observable<User> {
    const url = `${environment.teacherAPI}/teachers/${id}/`;

    if (isNaN(id)) {
      return throwError('Neplatné ID pedagoga');
    }

    return this.http.get<TeacherContract>(url).pipe(
      map((teacherData) => {
        const user = User.deserialize(teacherData.user);
        Teacher.deserialize(teacherData, user);
        return user;
      })
    );
  }

  /**
   * Handle errors from create/update teacher API calls.
   *
   * @param err
   * @private
   */
  private handleError(err: any): Observable<never> {
    const errorData = err.error;
    const result = new UserFormErrors();

    if (errorData.errors === undefined) {
      result.error = 'Pedagoga se nepovedlo upravit, zkuste to prosím později.';
      return throwError(result);
    }

    for (const errorItem of errorData.errors) {
      if (errorItem.field === 'email' || errorItem.field === 'username') {
        result.emailError = errorItem.message;
      }
      if (errorItem.field === 'first_name') {
        result.firstNameError = errorItem.message;
      }
      if (errorItem.field === 'last_name') {
        result.lastNameError = errorItem.message;
      }
      if (errorItem.field === 'password') {
        result.passwordError = errorItem.message;
      }
    }

    return throwError(result);
  }
}
