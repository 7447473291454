import { EvaluationContract } from '.';

/**
 * Result in one psychological category.
 */
export interface GroupCategoryResultContract {
  category_uuid: string;
  max_score: number;
  percent: number;
  score: number;
  evaluation: EvaluationContract;
}
