import { TeacherContract } from '../../contracts/teacher.contract';
import { TeacherGroup } from '../../enums';
import { User } from '..';

export class Teacher {
  constructor(public id: number, public active: boolean, public groups: Array<string> = [], public kindergartenIds: Array<number> = []) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param user
   */
  public static deserialize(data: TeacherContract, user: User): Teacher {
    let kindergartenIds: number[] = [];
    if (data.kindergarten_ids) kindergartenIds = data.kindergarten_ids;

    const teacher = new Teacher(data.id, data.is_active, data.groups, kindergartenIds);
    user.teacher = teacher;
    return teacher;
  }

  /**
   * Return teacher object serialized to basic javascript types.
   */
  public serialize(): TeacherContract {
    return {
      id: this.id,
      is_active: this.active,
      groups: this.groups,
      kindergarten_ids: this.kindergartenIds
    };
  }

  /**
   * Check if teacher is in a group.
   *
   * @param groupName
   */
  public inGroup(groupName: string): boolean {
    return this.groups.indexOf(groupName) >= 0;
  }

  /**
   * Check if teacher is manager.
   */
  public isManager(): boolean {
    return this.inGroup(TeacherGroup.MANAGER);
  }

  /**
   * Check if teacher belongs to a kindergarten.
   *
   * @param kindergartenId
   */
  public isFromKindergarten(kindergartenId: number): boolean {
    return this.kindergartenIds.indexOf(kindergartenId) >= 0;
  }

  /**
   * It updates teacher data with data of other teacher.
   *
   * @param other
   */
  public replaceData(other: Teacher) {
    this.id = other.id;
    this.active = other.active;
    this.groups = [...other.groups];
    this.kindergartenIds = [...other.kindergartenIds];
  }
}
