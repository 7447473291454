/**
 * Service to send HTTP request.
 *
 * It requires to set up this field into isophi-core library:
 * - app-keyword
 * - app-version
 */
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IsophiCoreService } from './isophi-core.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(protected http: HttpClient, protected isophiCoreService: IsophiCoreService) {}

  /**
   * General method to send any supported method type (GET, POST, PATCH).
   *
   * @deprecated
   *
   * @param requestMethod
   * @param url
   * @param data
   * @param token
   * @param contentType
   * @param responseType
   */
  request<T = any>(
    requestMethod: string,
    url: string,
    data: null | Map<string, any>,
    token: string | null = null,
    contentType: string | null = 'application/json',
    responseType: string | null = null
  ): Observable<HttpResponse<T>> {
    if (requestMethod === 'GET') {
      return this.get<T>(url, token, data, contentType, responseType);
    } else if (requestMethod === 'POST') {
      return this.post<T>(url, data, token, contentType, responseType);
    } else if (requestMethod === 'PUT') {
      return this.put<T>(url, data, token, contentType, responseType);
    } else if (requestMethod === 'DELETE') {
      return this.delete<T>(url, token, data, contentType, responseType);
    } else if (requestMethod === 'PATCH') {
      return this.patch<T>(url, data, token, contentType, responseType);
    } else {
      throw new Error(`Invalid request method: "${requestMethod}"`);
    }
  }

  /**
   * Send POST request.
   *
   * @deprecated
   *
   * @param url
   * @param postParams
   * @param token
   * @param contentType
   * @param responseType
   */
  post<T = any>(
    url: string,
    postParams: Map<string, any>,
    token: string | null = null,
    contentType: string | null = 'application/json',
    responseType: string | null = null
  ): Observable<HttpResponse<T>> {
    let headers = new HttpHeaders();
    if (contentType !== null) headers = headers.set('Content-Type', contentType);
    if (token !== null) headers = headers.set('Authorization', 'Bearer ' + token);

    let httpParams = new HttpParams();
    httpParams = httpParams.set(this.isophiCoreService.appKeyword + '-version', this.isophiCoreService.appVersion);

    let body = null;
    if (contentType === 'application/x-www-form-urlencoded') {
      let encodedParams = new HttpParams();
      postParams.forEach((val, key) => {
        encodedParams = encodedParams.set(key, val);
      });
      body = encodedParams.toString();
    } else if (contentType === 'application/json') {
      body = {};
      postParams.forEach((val, key) => (body[key] = val));
    }

    const requestOptions: any = { headers, observe: 'response', params: httpParams };
    if (responseType) requestOptions.responseType = responseType;

    return this.http.post<T>(url, body, requestOptions) as Observable<HttpResponse<T>>;
  }

  /**
   * Send PUT request.
   *
   * @deprecated
   *
   * @param url
   * @param putParams
   * @param token
   * @param contentType
   * @param responseType
   */
  put<T = any>(
    url: string,
    putParams: Map<string, any>,
    token: string | null = null,
    contentType: string | null = 'application/json',
    responseType: string | null = null
  ): Observable<HttpResponse<T>> {
    let headers = new HttpHeaders();
    if (contentType !== null) headers = headers.set('Content-Type', contentType);
    if (token !== null) headers = headers.set('Authorization', 'Bearer ' + token);

    let httpParams = new HttpParams();
    httpParams = httpParams.set(this.isophiCoreService.appKeyword + '-version', this.isophiCoreService.appVersion);

    let body = null;
    if (contentType === 'application/x-www-form-urlencoded') {
      let encodedParams = new HttpParams();
      putParams.forEach((val, key) => {
        encodedParams = encodedParams.set(key, val);
      });
      body = encodedParams.toString();
    } else if (contentType === 'application/json') {
      body = {};
      putParams.forEach((val, key) => (body[key] = val));
    }

    const requestOptions: any = { headers, observe: 'response', params: httpParams };
    if (responseType) requestOptions.responseType = responseType;

    return this.http.put<T>(url, body, requestOptions) as Observable<HttpResponse<T>>;
  }

  /**
   * Send PATCH request.
   *
   * @deprecated
   *
   * @param url
   * @param patchParams
   * @param token
   * @param contentType
   * @param responseType
   */
  patch<T = any>(
    url: string,
    patchParams: Map<string, any>,
    token: string | null = null,
    contentType: string | null = 'application/json',
    responseType: string | null = null
  ): Observable<HttpResponse<T>> {
    let headers = new HttpHeaders();
    if (contentType !== null) headers = headers.set('Content-Type', contentType);
    if (token !== null) headers = headers.set('Authorization', 'Bearer ' + token);

    let httpParams = new HttpParams();
    httpParams = httpParams.set(this.isophiCoreService.appKeyword + '-version', this.isophiCoreService.appVersion);

    let body = null;
    if (contentType === 'application/x-www-form-urlencoded') {
      let encodedParams = new HttpParams();
      patchParams.forEach((val, key) => {
        encodedParams = encodedParams.set(key, val);
      });
      body = encodedParams.toString();
    } else if (contentType === 'application/json') {
      body = {};
      patchParams.forEach((val, key) => (body[key] = val));
    }

    const requestOptions: any = { headers, observe: 'response', params: httpParams };
    if (responseType) requestOptions.responseType = responseType;

    return this.http.patch<T>(url, body, requestOptions) as Observable<HttpResponse<T>>;
  }

  /**
   * Send GET request.
   *
   * @deprecated
   *
   * @param url
   * @param token
   * @param getParams
   * @param contentType
   * @param responseType
   */
  get<T = any>(
    url: string,
    token: string | null = null,
    getParams: null | Map<string, any> = null,
    contentType: string | null = 'application/json',
    responseType: string | null = null
  ): Observable<HttpResponse<T>> {
    let headers = new HttpHeaders();
    let httpParams = new HttpParams();
    httpParams = httpParams.set(this.isophiCoreService.appKeyword + '-version', this.isophiCoreService.appVersion);

    if (contentType !== null) headers = headers.set('Content-Type', contentType);
    if (token !== null) headers = headers.set('Authorization', 'Bearer ' + token);
    if (getParams) {
      getParams.forEach((val: any, key: string) => {
        httpParams = httpParams.set(key, val);
      });
    }

    const requestOptions: any = { headers, observe: 'response', params: httpParams };
    if (responseType) requestOptions.responseType = responseType;

    return this.http.get<T>(url, requestOptions) as Observable<HttpResponse<T>>;
  }

  /**
   * Send DELETE request.
   *
   * @deprecated
   *
   * @param url
   * @param token
   * @param getParams
   * @param contentType
   * @param responseType
   */
  delete<T = any>(
    url: string,
    token: string | null = null,
    getParams: null | Map<string, any> = null,
    contentType: string | null = 'application/json',
    responseType: string | null = null
  ): Observable<HttpResponse<T>> {
    let headers = new HttpHeaders();
    let httpParams = new HttpParams();
    httpParams = httpParams.set(this.isophiCoreService.appKeyword + '-version', this.isophiCoreService.appVersion);

    if (contentType !== null) headers = headers.set('Content-Type', contentType);
    if (token !== null) headers = headers.set('Authorization', 'Bearer ' + token);
    if (getParams) {
      getParams.forEach((val: any, key: string) => {
        httpParams = httpParams.set(key, val);
      });
    }

    const requestOptions: any = { headers, observe: 'response', params: httpParams };
    if (responseType) requestOptions.responseType = responseType;

    return this.http.delete<T>(url, requestOptions) as Observable<HttpResponse<T>>;
  }

  /**
   * Create options param for HttpClient.
   *
   * Add authorization header.
   * Add app keyword + version query param.
   *
   * @param authToken Authorization OAuth 2.0 token for communication with server.
   */
  public createRequestOptions(authToken: string | null = null): { headers: HttpHeaders; params: HttpParams } {
    let httpParams = new HttpParams();
    httpParams = httpParams.set(this.isophiCoreService.appKeyword + '-version', this.isophiCoreService.appVersion);

    let httpHeaders = new HttpHeaders();
    if (authToken) httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + authToken);

    return { headers: httpHeaders, params: httpParams };
  }
}
