import { HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { HttpService } from '../../../services';

export class HttpServiceMock extends HttpService {
  public postResponses: Array<HttpResponse<any>> = [];

  public putResponses: Array<HttpResponse<any>> = [];

  public patchResponses: Array<HttpResponse<any>> = [];

  public getResponses: Array<HttpResponse<any>> = [];

  public deleteResponses: Array<HttpResponse<any>> = [];

  post(
    _url: string,
    _postParams: Map<string, any>,
    _token: string | null = null,
    _contentType: string | null = 'application/json'
  ): Observable<HttpResponse<any>> {
    return of<HttpResponse<any>>(this.getResponse(this.postResponses));
  }

  put(
    _url: string,
    _putParams: Map<string, any>,
    _token: string | null = null,
    _contentType: string | null = 'application/json'
  ): Observable<HttpResponse<any>> {
    return of<HttpResponse<any>>(this.getResponse(this.putResponses));
  }

  patch(
    _url: string,
    _patchParams: Map<string, any>,
    _token: string | null = null,
    _contentType: string | null = 'application/json'
  ): Observable<HttpResponse<any>> {
    return of<HttpResponse<any>>(this.getResponse(this.patchResponses));
  }

  get(
    _url: string,
    _token: string | null = null,
    _getParams: Map<string, any> | null = null,
    _contentType: string | null = 'application/json'
  ): Observable<HttpResponse<any>> {
    return of<HttpResponse<any>>(this.getResponse(this.getResponses));
  }

  delete(
    _url: string,
    _token: string | null = null,
    _getParams: Map<string, any> | null = null,
    _contentType: string | null = 'application/json'
  ): Observable<HttpResponse<any>> {
    return of<HttpResponse<any>>(this.getResponse(this.deleteResponses));
  }

  public createSuccessResponse(requestMethod: string, data: any) {
    if (requestMethod === 'GET') {
      this.getResponses.push(new HttpResponse<any>({ status: 200, body: data }));
    } else if (requestMethod === 'POST') {
      this.postResponses.push(new HttpResponse<any>({ status: 200, body: data }));
    } else if (requestMethod === 'PUT') {
      this.putResponses.push(new HttpResponse<any>({ status: 200, body: data }));
    } else if (requestMethod === 'DELETE') {
      this.deleteResponses.push(new HttpResponse<any>({ status: 200, body: data }));
    } else if (requestMethod === 'PATCH') {
      this.patchResponses.push(new HttpResponse<any>({ status: 200, body: data }));
    } else {
      throw new Error(`Invalid request method: "${requestMethod}".`);
    }
  }

  protected getResponse(responses: Array<any>): any {
    if (responses.length > 0) {
      return responses.splice(0, 1)[0];
    } else {
      throw new Error('No HTTP response.');
    }
  }
}
