/**
 * Class to represent kindergarten.
 */
import { KindergartenContract } from '../../contracts/kindergarten.contract';
import { OrgTypeContract } from '../../contracts/org-type.contract';

export class OrgType {
  constructor(
    public id: number,
    public orgType: string
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: OrgTypeContract): OrgType {
    return new OrgType(data.id, data.org_type);
  }

  /**
   * Serialize typescript object into basic javascript types.
   */
  public serialize(): OrgTypeContract {
    return {
      id: this.id,
      org_type: this.orgType
    };
  }
}
