import { ParentContract } from '../../contracts/parent.contract';
import { User } from '..';

export class Parent {
  constructor(public id: number, public active: boolean) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param user
   */
  public static deserialize(data: ParentContract, user: User): Parent {
    const parent = new Parent(data.id, data.is_active);
    user.parent = parent;
    return parent;
  }

  /**
   * Return teacher object serialized to basic javascript types.
   */
  public serialize(): ParentContract {
    return {
      id: this.id,
      is_active: this.active
    };
  }

  /**
   * It updates parent data with data of other teacher.
   *
   * @param other
   */
  public replaceData(other: Parent) {
    this.id = other.id;
    this.active = other.active;
  }
}
