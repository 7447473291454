import { Category, Child } from '@isophi/core-legacy';
import { GroupSortedChildResultContract } from '../contracts';
import { Evaluation } from './evaluation.model';
import { SortedReportCategoryResult } from './sorted-report-category-result.model';

export class SortedReportChildResult {
  constructor(public child: Child, public percentScore: number, public categoryResults: SortedReportCategoryResult[]) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param child
   * @param categories
   * @param evaluations
   */

  public static deserialize(
    data: GroupSortedChildResultContract,
    child: Child,
    categories: Category[],
    evaluations: Evaluation[]
  ): SortedReportChildResult {
    return new SortedReportChildResult(
      child,
      data.percent_score,
      data.category_results.map(
        (categoryResult) =>
          new SortedReportCategoryResult(
            categories.find((c) => c.uuid === categoryResult.category_uuid),
            evaluations.find((e) => e.code === categoryResult.evaluation_code),
            categoryResult.percent_score
          )
      )
    );
  }
}
