import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HoverEffectSidebarDirective } from './directives/hover-effect-sidebar.directive';
import { SidemenuToggleDirective } from './directives/sidemenuToggle';

const COMPONENTS = [HoverEffectSidebarDirective, SidemenuToggleDirective];
@NgModule({
  imports: [CommonModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class UiTemplateModule {}
