import { CirilCategoryResultContract } from '.';
import { IndividualCategoryResultContract } from '.';

/**
 * Result in one unit (test/questionnaire/...)
 */
export interface IndividualChildResultContract {
  child_uuid?: string;
  source_uuid?: string;
  data_set_uuid?: string;
  max_score?: number;
  percent?: number;
  score?: number;
  finished_at?: string;
  category_results?: IndividualCategoryResultContract[];
  source?: 'dad_test' | 'dad_test_z' | 'smart' | 'game' | 'questionnaire';
  child_age?: number;
  calculated_category_results?: CirilCategoryResultContract;
  visible_to_parent?: boolean;
}
