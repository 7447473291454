import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const padNumber = (value: number) => (isNumber(value) ? `0${value}`.slice(-2) : '');

const isNumber = (value: number | string): boolean => !isNaN(toInteger(value));

const toInteger = (value: number | string): number => parseInt(`${value}`, 10);

@Injectable()
export class NgbDateCSParserFormatter extends NgbDateParserFormatter {
  public parse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('.');
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { year: toInteger(dateParts[0]), month: null } as any;
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return { year: toInteger(dateParts[1]), month: toInteger(dateParts[0]), day: null } as any;
      } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
        return { year: toInteger(dateParts[2]), month: toInteger(dateParts[1]), day: toInteger(dateParts[0]) };
      }
    }
    return null;
  }

  public format(date: NgbDateStruct): string {
    let stringDate = '';
    if (date) {
      stringDate += isNumber(date.day) ? padNumber(date.day) + '.' : '';
      stringDate += isNumber(date.month) ? padNumber(date.month) + '.' : '';
      stringDate += date.year;
    }
    return stringDate;
  }
}
