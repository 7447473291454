import { Teacher } from '../kindergarten/teacher.model';
import { Parent } from '../parent/parent.model';
import { IUserData } from '../types';
import { Password } from './password.model';

/**
 * Class representing one user of iSophi ecosystem.
 */
export class User {
  public groups: Array<string> = [];

  public teacher: Teacher | null = null;

  public parent: Parent | null = null;

  public count?: number = 0;

  constructor(
    public id: number,
    public uuid: string | null,
    public username: string,
    public email: string,
    public firstName: string,
    public lastName: string,
    public active: boolean,
    public password: Password | null = null
  ) {}

  /**
   * Return full name of user.
   */
  public get fullName(): string {
    return `${this.lastName} ${this.firstName}`;
  }

  public get counter(): number {
    return this.count;
  }

  /**
   * Return if User and all roles of user are active.
   */
  public get isAllActive(): boolean {
    let active: boolean = this.active;
    if (this.parent !== null) active = active && this.parent.active;
    if (this.teacher !== null) active = active && this.teacher.active;
    return active;
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: IUserData): User {
    let password = null;
    if (Object.prototype.hasOwnProperty.call(data, 'password_data')) {
      password = Password.deserialize(data.password_data);
    }
    let uuid = null;
    if (Object.prototype.hasOwnProperty.call(data, 'uuid')) {
      uuid = data.uuid;
    }

    const user = new User(data.id, uuid, data.username, data.email, data.first_name, data.last_name, data.is_active, password);

    if (Object.prototype.hasOwnProperty.call(data, 'group_string') && data.group_string !== '') {
      user.groups.push(...data.group_string.split(' '));
    }
    if (Object.prototype.hasOwnProperty.call(data, 'parent') && typeof data.parent === 'object' && data.parent !== null) {
      Parent.deserialize(data.parent, user);
    }
    if (Object.prototype.hasOwnProperty.call(data, 'teacher') && typeof data.teacher === 'object' && data.teacher !== null) {
      Teacher.deserialize(data.teacher, user);
    }

    return user;
  }

  public setCounter(index: number) {
    this.count = index;
  }

  /**
   * Return user object serialized to basic javascript types.
   */
  public serialize(): IUserData {
    return {
      id: this.id,
      uuid: this.uuid,
      username: this.username,
      email: this.email,
      first_name: this.firstName,
      last_name: this.lastName,
      is_active: this.active,
      password_data: this.password ? this.password.serialize() : null,
      group_string: this.groups.join(' '),
      parent: this.parent === null ? null : this.parent.serialize(),
      teacher: this.teacher === null ? null : this.teacher.serialize()
    };
  }

  /**
   * It updates user data with data of other user.
   *
   * @param other
   * @param nested    True/False if update data in nested models (teacher, parent, ...)
   */
  public updateData(other: User, nested: boolean = false) {
    this.id = other.id;
    this.uuid = other.uuid;
    this.username = other.username;
    this.email = other.email;
    this.firstName = other.firstName;
    this.lastName = other.lastName;
    this.password = other.password;
    this.active = other.active;
    this.groups = [...other.groups];
    this.password = Password.deserialize(other.password.serialize());

    if (nested && this.teacher && other.teacher) {
      this.teacher.replaceData(other.teacher);
    }
    if (nested && this.parent && other.parent) {
      this.parent.replaceData(other.parent);
    }
  }
}
