import { Child } from './child.model';
import { Test } from './test.model';
import { Game } from './game.model';
import { Questionnaire } from './questionnaire.model';
import { IndividualChildResultPreviewContract } from '../contracts';
import { DateHelper } from '@isophi/core-legacy';

export class IndividualChildResultPreview {
  public constructor(
    public uuid: string,
    public test: Test | Game | Questionnaire,
    public child: Child,
    public finishedAt: Date
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param test
   * @param child
   */
  public static deserialize(
    data: IndividualChildResultPreviewContract,
    test: Test | Game | Questionnaire,
    child: Child
  ): IndividualChildResultPreview {
    return new IndividualChildResultPreview(
      data.uuid,
      test,
      child,
      DateHelper.parse(data.finished_at)
    );
  }
}
