/**
 * Service to manage parent data.
 */
import { Injectable } from '@angular/core';

import { Parent, User } from '../../models';
import { HttpService } from '../core/http.service';
import { IsophiCoreService } from '../core/isophi-core.service';

@Injectable({
  providedIn: 'root'
})
export class ParentService {
  constructor(protected httpService: HttpService, protected isophiCoreService: IsophiCoreService) {}

  /**
   * This method download and save parent data into User entity.
   *
   * @param user
   * @param accessToken
   * @return true/false if data was successfully downloaded
   */
  public loadParentData(user: User, accessToken: string): Promise<boolean> {
    const appApi = this.isophiCoreService.appApi;
    const url = `${appApi}/users/${user.id}/parent/`;

    return this.httpService
      .get(url, accessToken)
      .toPromise()
      .then((response) => {
        if (response.status !== 200) return false;

        const data = response.body;
        Parent.deserialize(data, user);
        return true;
      })
      .catch(() => false);
  }
}
