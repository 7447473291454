/**
 * @filed title on request as name
 */

export interface GameContract {
  uuid: string;
  name?: string;
  short_title: string;
  color: string;
  product_codename: string;
  source: string;
  game_url?: string;
  description?: string;
  title?: string;
}
