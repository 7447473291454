/**
 * Features for product type test.
 */
export enum LicenseTestFeature {
  TEST_KIT = 'test_kit',
  TEST_PREVIEW = 'test_preview',
  TEST_RECORD = 'test_record',
  DISPLAY_TEST_RESULT = 'display_test_result',
  DISPLAY_FILLED_TEST = 'display_filled_test',
  TEST_EDIT = 'test_edit',
  PRINTED_REPORT_TEACHER = 'printed_report_teacher',
  PRINTED_REPORT_PARENT = 'printed_report_parent',
  PRINTED_REPORT_COMPARISON = 'printed_report_comparison',
  PRINTED_REPORT_GROUP = 'printed_report_group',
  PRINTED_REPORT_MULTIGRAPH = 'printed_report_multigraph',
  PRINTED_REPORT_GROUP_SORTED = 'printed_report_group_sorted',
  PRINTED_REPORT_STEM = 'printed_report_stem',
  ONLINE_REPORT_TEACHER = 'online_report_teacher',
  ONLINE_REPORT_GROUP = 'online_report_group',
  ONLINE_REPORT_MULTIGRAPH = 'online_report_multigraph',
  ONLINE_REPORT_GROUP_SORTED = 'online_report_group_sorted',
  ONLINE_REPORT_STEM = 'online_report_stem',
  TEST_SUPPORT = 'test_support',
  TEST_SUPPORT_VIDEO = 'test_support_video'
}
