import { EvaluationContract } from '../contracts';
import { IndividualCategoryResultContract } from '../contracts';

export class Evaluation {
  public constructor(public title: string, public code: number, public backgroundColor: string, public textColor: string) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: EvaluationContract | IndividualCategoryResultContract): Evaluation {
    if (Object.prototype.hasOwnProperty.call(data, 'evaluation_code')) {
      data = data as IndividualCategoryResultContract;
      return new Evaluation(data.evaluation_title, data.evaluation_code, data.evaluation_background_color, data.evaluation_text_color);
    } else {
      data = data as EvaluationContract;
      return new Evaluation(data.title, data.code, data.background_color, data.text_color);
    }
  }
}
