import { ComparisonReportSettingsContract } from '../contracts';
import { Group } from './group.model';
import { ReportSettings } from './report-settings.interface';

export class ComparisonReportSettings implements ReportSettings {
  public constructor(public firstDataSetUuid: string, public secondDataSetUuid: string) {}

  /**
   * todo(doubravskytomas): improve parent class
   * todo(doubravskytomas): getGroups method should not be required in individual reports
   */
  public getGroups(): Array<Group> | null {
    return [];
  }

  /**
   * Return Report object serialized to basic javascript types.
   */
  public serialize(): ComparisonReportSettingsContract {
    return {
      data_set_uuids: [this.firstDataSetUuid, this.secondDataSetUuid].join(','),
    };
  }

  /**
   * Return Report object serialized to basic javascript types without any nested fields.
   */
  public apiSerialize(): ComparisonReportSettingsContract {
    return {
      data_set_uuids: [this.firstDataSetUuid, this.secondDataSetUuid].join(','),
    };
  }
}
