/**
 * Source of data with licenses.
 *
 * API - Data are downloaded from API by LicenseService.
 * LOCAL_DATA - Data are passed to LicenseService by library client (application).
 */
export enum LicenseDataSource {
  API = 'api',
  LOCAL_DATA = 'local_data'
}
