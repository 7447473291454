/**
 * Child data contract.
 *
 * @field id only when data come from server
 * @field uuid when data come from server OR creating new record
 * @field revision only when data come from server
 * @field results_count on request as extra_field; it is a dictionary where keys are test uuids
 * @field group_uuids only when data come from server; extra field for request
 * @field group_names only when data come from server; extra field for request
 * @field set_groups only for upload; set child to groups during editing child
 */
export interface ChildContract {
  id?: number;
  uuid?: string;
  first_name: string;
  last_name: string;
  gender: string;
  birth_date: string;
  revision?: number;
  results_count?: Record<string, number>;
  group_uuids?: string[];
  group_names?: string[];
  set_groups?: string[];
  is_connected_to_parent?: boolean;
}
