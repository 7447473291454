import * as Factory from 'factory.ts';

export class BaseFactory<I, C> {
  protected randomDataFactory: Factory.Factory<any> | null;

  protected dataFactory: Factory.Factory<any> | null;

  protected classRef: any;

  public getData(params?: any): I {
    if (params !== undefined) {
      return this.dataFactory.build(params);
    } else {
      return this.dataFactory.build();
    }
  }

  public getRandomData(params?: any): I {
    if (params !== undefined) {
      return this.randomDataFactory.build(params);
    } else {
      return this.randomDataFactory.build();
    }
  }

  public getRandomDataList(count: number, params?: any): Array<I> {
    if (params !== undefined) {
      return this.randomDataFactory.buildList(count, params);
    } else {
      return this.randomDataFactory.buildList(count);
    }
  }

  public build(params?: any): C {
    return this.classRef.deserialize(this.getData(params));
  }

  public buildRandom(params?: any): C {
    return this.classRef.deserialize(this.getRandomData(params));
  }

  public buildRandomList(count: number, params?: any): Array<C> {
    const data = this.getRandomDataList(count, params);
    return data.map((item) => this.classRef.deserialize(item));
  }
}
