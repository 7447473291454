import { Group } from './group.model';

/**
 * Report settings for any report.
 */
export interface ReportSettings {
  serialize(): any;

  apiSerialize(): any;

  getGroups(): Array<Group> | null;
}
