import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { CategoryBarChartComponent } from './components/category-bar-chart/category-bar-chart.component';
import { ChartHintComponent } from './components/chart-hint/chart-hint.component';
import { ChildAgeComponent } from './components/child-age/child-age.component';
import { HexagonComponent } from './components/hexagon/hexagon.component';
import { LanguagePickerComponent } from './components/language-picker/language-picker.component';
import { LockIconComponent } from './components/lock-icon/lock-icon.component';
import { PercentageComponent } from './components/percentage/percentage.component';
import { SimpleBadgeComponent } from './components/simple-badge/simple-badge.component';
import { SocialNetworksIconsComponent } from './components/social-networks-icons/social-networks-icons.component';
import { ToastsComponent } from './components/toasts/toasts.component';
import TrackByPropertyDirective from './directives/track-by-property.directive';
import { SafePipe } from './pipes/safe.pipe';
import { WithLoaderPipe } from './pipes/with-loader.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';

const COMPONENTS = [
  ChildAgeComponent,
  LanguagePickerComponent,
  LockIconComponent,
  SimpleBadgeComponent,
  SocialNetworksIconsComponent,
  ToastsComponent,
  TrackByPropertyDirective,
  WithLoaderPipe,
  SafePipe,
  CategoryBarChartComponent,
  HexagonComponent,
  ChartHintComponent,
  PercentageComponent,
  FileSizePipe
];
@NgModule({
  imports: [CommonModule, TranslocoModule, NgbToastModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS, TranslocoModule],
})
export class CoreUiModule {}
