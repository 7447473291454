<div class="wrap">
  <div class="page">
    <div class="page-main">
      <app-header></app-header>

      <div class="app-content main-content">
        <app-subnav *ngIf="showSubNav$ | async as show" [showSubNav]="show"></app-subnav>
        <div class="container main-container" [class]="{ 'main-container-background': urlWithoutBackground }">
          <router-outlet></router-outlet>
        </div>
      </div>

      <app-footer></app-footer>
    </div>
  </div>
</div>
