import { Component, Input } from '@angular/core';

/**
 * A component for displaying info, that the user does not have license.
 * It may contain warning and information plus images (preview of the licensed functionality).
 */
@Component({
  selector: 'app-upgrade-license-preview',
  templateUrl: './upgrade-license-preview.component.html',
  styleUrls: ['./upgrade-license-preview.component.scss']
})
export class UpgradeLicensePreviewComponent {
  @Input() public warningText?: string;

  @Input() public infoTitle: string;

  @Input() public infoText: string;

  @Input() public previewTitle?: string;

  @Input() public previewImgPath?: string;

  @Input() public previewImgPath2?: string;
}
