<div class="hint mt-1" [class]="theme">
  <p class="mb-2" *ngIf="text; else staticText">{{ text }}</p>
  <ng-template #staticText>
    <p class="mb-2">Úroveň dovednosti:</p>
  </ng-template>
  <div class="d-flex">
    <div *ngFor="let evaluation of evaluations" class="d-flex">
      <p class="hint-color" [style]="{ 'background-color': evaluation.backgroundColor }"></p>
      <p class="hint-content">{{ evaluation.title }}</p>
    </div>
  </div>
</div>
