import { Component, Input } from '@angular/core';

/**
 * Reusable component for simple badges such as a test badge.
 * Title, background color may be changed.
 * Extra classes could be added.
 */
@Component({
  selector: 'isophi-simple-badge',
  templateUrl: './simple-badge.component.html',
  styleUrls: ['./simple-badge.component.css'],
})
export class SimpleBadgeComponent {
  @Input() public title?: string;

  @Input() public fontSize?: number = 0;

  @Input() public bgColor?: string = '#000000';

  @Input() public extraClass?: string;

  public useExtraStyles(): object {
    const styles: any = {
      'background-color': this.bgColor,
    }; // Could be initiated with "extraStyles" attribute in the future
    if (this.fontSize && this.fontSize > 0) styles['font-size'] = this.fontSize + 'rem';
    return styles;
  }

  public useExtraClass(): string {
    return this.extraClass ? this.extraClass : '';
  }
}
