export interface IStorage {
  /**
   * Save item into storage.
   *
   * @param key
   * @param value
   * @param removeOnLogout flag if the key should be removed on logout
   */
  setItem(key: string, value: string, removeOnLogout?: boolean);

  /**
   * Load item from storage.
   * It returns null, if key does not exist in the storage.
   *
   * @param key
   */
  getItem(key: string): string | null;

  /**
   * Check if storage has item with key.
   *
   * @param key
   */
  hasItem(key: string): boolean;

  /**
   * Remove item from storage.
   * If key does not exist, this do nothing.
   *
   * @param key
   */
  removeItem(key: string): void;

  /**
   * Removes all items which should be removed on logout.
   */
  removeOnLogout(): void;

  /**
   * Clear whole storage.
   */
  clear();

  /**
   * Return storage identifier.
   */
  getIdentifier(): string;
}
