import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

import { RouterLinkFactory } from '../../../../core/router/router-link.factory';
import { UserDto } from '../../../../modules/kindergarten/dto/user.dto';
import { UserFormErrors } from '../../../../modules/kindergarten/dto/user-form-errors.dto';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserFormComponent {
  @Input()
  public userDto: UserDto;

  @Input()
  public submitButtonText: string;

  @Input()
  public showPasswordField = true;

  @Input()
  public disabled = false;

  @Input()
  public selfEdit = true;

  @Output()
  public submitForm = new EventEmitter<UserDto>();

  public formErrors: UserFormErrors = new UserFormErrors();

  public forgottenPasswordLink: (string | number)[];

  constructor(private cd: ChangeDetectorRef, public links: RouterLinkFactory) {
    this.forgottenPasswordLink = this.links.forgottenPassword();
  }

  public onSubmitForm(): void {
    this.formErrors.clearData();
    this.submitForm.emit(this.userDto);
  }

  public updateFormErrors(errors: UserFormErrors): void {
    this.formErrors.update(errors);
    this.cd.markForCheck();
  }
}
