<div class="filter-container" *transloco="let t">
  <div class="row no-gutters">
    <div class="col">
      <h6 class="fw-bold mb-5">
        <i class="fe fe-filter fw-bold"></i>
        {{ t('child/searchAndFilter') }}
      </h6>
      <div class="filtering-inputs">
        <ng-container *ngIf="!filterProductActive">
          <div class="form-group class-select" *ngIf="archiveGroups$ | async as archiveGroups">
            <label for="archiveGroups">
              {{ t('child/yearToArchive') }}
            </label>
            <ng-select
              id="archiveGroups"
              [items]="archiveGroups"
              [multiple]="false"
              placeholder="Vše"
              (change)="refresh()"
              [(ngModel)]="selectedArchiveGroup"
            >
            </ng-select>
          </div>
        </ng-container>

        <div class="form-group class-select" *ngIf="childrenGroups$ | async as childrenGroups">
          <label for="groups">
            {{ t('child/group') }}
          </label>
          <ng-select
            id="groups"
            name="groups"
            [items]="childrenGroups"
            bindLabel="name"
            [multiple]="true"
            (change)="groupFilter.next(selectedGroups)"
            [placeholder]="t('child/selectGroupToFilter')"
            [(ngModel)]="selectedGroups"
          >
          </ng-select>
        </div>

        <div class="form-group search-child">
          <label>
            {{ t('shared/selectChild') }}
          </label>
          <input
            [formControl]="nameFilter"
            type="text"
            class="form-control"
            [placeholder]="t('child/searchChild')"
            data-cy-test="search-child"
          />
          <img src="assets/img/search.svg" alt="icon" class="search-child-icon" />
        </div>

        <div *ngIf="filterProductActive" class="form-group select-product mt-6 mb-0">
          <label class="fw-bold mb-5">{{ t('child/selectTool') }}</label>

          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="tool"
              id="all"
              [value]="ProductType.ALL"
              [(ngModel)]="selectedSource"
              (change)="handleChange(selectedSource, undefined)"
            />
            <label class="form-check-label" for="all"> {{ t('child/resultFromAllTools') }} </label>
          </div>

          <ng-container *ngFor="let productGroupValue of productGroups$ | async">
            <div *ngIf="productGroupValue.showInFilter" class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="tool"
                [id]="productGroupValue.name"
                [value]="productGroupValue.name"
                [(ngModel)]="selectedSource"
                (change)="handleChange(selectedSource, productGroupValue)"
              />
              <label class="form-check-label" [for]="productGroupValue.name">
                {{ productGroupValue.title }}
                <img [src]="productGroupValue.shortLogo" [alt]="productGroupValue.title" />
              </label>
            </div>
          </ng-container>
        </div>

        <button (click)="clearFilters(!filterProductActive && true)" class="btn btn-outline-dark mt-4 float-end clear-btn">
          {{ t('child/clearFilter') }}
        </button>
      </div>
    </div>
  </div>
</div>
