/**
 * Result in one psychological category.
 */

export interface GroupSortedCategoryResultContract {
  category_uuid: string;
  percent_score: number;
  evaluation_title: string;
  evaluation_code: number;
  evaluation_text_color: string;
  evaluation_background_color: string;
}
