import { GroupSortedCategoryResultContract } from '.';

/**
 * Result in one unit
 */

export interface GroupSortedChildResultContract {
  child_uuid: string;
  percent_score: number;
  category_results: GroupSortedCategoryResultContract[];
}
