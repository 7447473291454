import { Category } from '@isophi/core-legacy';
import { Evaluation } from './evaluation.model';

import { Test } from './test.model';

export class MultireportTestResult {
  public constructor(public category: Category, public finishedAt: Date, public evaluation: Evaluation | null, public test: Test) {}

  public get title(): string {
    return this.finishedAt.toLocaleDateString('cs-CZ', { month: 'numeric', year: 'numeric' });
  }
}
