import { Category, DateHelper } from '@isophi/core-legacy';

import { MultireportCategoryResultContract } from '../contracts/multireport-category-result.contract';
import { Evaluation } from './evaluation.model';
import { MultireportTestResult } from './multireport-test-result';
import { Test } from './test.model';

export class MultireportCategoryResult {
  public constructor(public category: Category, public tests: Test[], public testResults: MultireportTestResult[]) {}

  /**
   * Deserialize JSON to typescript object.
   */
  public static deserialize(
    data: MultireportCategoryResultContract,
    category: Category,
    tests: Test[],
    evaluations: Evaluation[]
  ): MultireportCategoryResult {
    return new MultireportCategoryResult(
      category,
      tests.sort((a, b) => b.maxAge - a.maxAge),
      data.test_results.map(
        (testResult) =>
          new MultireportTestResult(
            category,
            DateHelper.parse(testResult.finished_at),
            evaluations.find((e) => e.code === testResult.evaluation_code) || null,
            tests.find((test) => test.uuid === testResult.test_uuid)
          )
      )
    );
  }
}
