import { Child as BaseChild, DateHelper } from '@isophi/core-legacy';
import { ChildContract } from '../contracts';
import { Group } from './group.model';

import { Game } from './game.model';
import { ResultsCount } from './result-count.model';
import { Test } from './test.model';
import { Questionnaire } from './questionnaire.model';

export class Child extends BaseChild {
  public groups: Array<Group> = [];

  public testResultsCount: ResultsCount[] = [];

  public isConnectedToParent = false;

  /**
   * Create new Child with ID.
   *
   * Used this method when deserializing data from server.
   *
   * @param id
   * @param uuid
   * @param firstName
   * @param lastName
   * @param gender
   * @param birthDate
   * @param revision
   * @param isConnectedToParent
   */
  public static createWithId(
    id: number,
    uuid: string,
    firstName: string,
    lastName: string,
    gender: string,
    birthDate: Date,
    revision = 0,
    isConnectedToParent = false
  ): Child {
    const child = Child.create(uuid, firstName, lastName, gender, birthDate, revision, isConnectedToParent);
    child.id = id;
    return child;
  }

  /**
   * Create new Child without ID.
   *
   * Used this method when creating Child in the DAD app.
   *
   * @param uuid
   * @param firstName
   * @param lastName
   * @param gender
   * @param birthDate
   * @param revision
   * @param isConnectedToParent
   */
  public static create(uuid: string, firstName: string, lastName: string, gender: string, birthDate: Date, revision = 0, isConnectedToParent = false): Child {
    const child = new Child();
    child.uuid = uuid;
    child.name = firstName;
    child.surname = lastName;
    child.gender = gender;
    child.birthDate = birthDate;
    child.birthDate.setHours(0, 0, 0, 0); // Ensure time set to zero.
    child.revision = revision;
    child.isConnectedToParent = isConnectedToParent;
    return child;
  }

  /**
   * Deserializer child data into new Child.
   *
   * @param data
   */
  public static deserialize(data: ChildContract): Child {
    return Child.createWithId(
      data.id,
      data.uuid,
      data.first_name,
      data.last_name,
      data.gender,
      DateHelper.parse(data.birth_date),
      data.revision,
      data.is_connected_to_parent
    );
  }

  /**
   * Combines raw data about results count with more detailed data about tests.
   * Child.testResultsCount is initiated.
   *
   * @param rawResultsCount
   * @param games
   * @param tests Data about tests (id, short title etc.).
   * @param questionnaires
   */
  public initTestResultsCount(
    rawResultsCount: Record<string, number> | undefined,
    games: Game[],
    tests: Test[],
    questionnaires: Questionnaire[]
  ): void {
    if (!rawResultsCount) return;

    const gamesResultCount = [];
    const questionnairesResultCount = [];

    for (const key in rawResultsCount) {
      const test: Test | undefined = tests.find((t) => t.uuid === key);
      const game: Game | undefined = games.find((m) => m.uuid === key);
      const questionnaire: Questionnaire = questionnaires.find((q) => q.uuid === key);

      if (game) {
        const resultCount: ResultsCount = {
          count: rawResultsCount[key],
          shortTitle: game.shortTitle,
          color: game.color,
          source: game.source
        };
        gamesResultCount.push(resultCount);
      } else if (questionnaire) {
        const resultCount: ResultsCount = {
          count: rawResultsCount[key],
          shortTitle: 'assets/img/logo/iSophiR.png',
          color: test?.color,
          source: 'questionnaire'
        };
        questionnairesResultCount.push(resultCount);
      } else {
        if (!test) continue;
        const resultCount: ResultsCount = {
          count: rawResultsCount[key],
          shortTitle: test.shortTitle,
          color: test.color,
          source: (test.productCodename === 'test_z' || test.productCodename === 'test_z_old') ? 'dad_test_z' : 'dad_test'
        };
        this.testResultsCount.push(resultCount);
      }
    }
    this.testResultsCount.sort((a, b) => {
      const testA = a.shortTitle.substr(1, 1);
      const testB = b.shortTitle.substr(1, 1);
      if (testA < testB) return -1;
      if (testA > testB) return 1;
      return 0;
    });
    this.testResultsCount.push(...gamesResultCount);
    this.testResultsCount.push(...questionnairesResultCount);
  }

  // todo(doubravskytomas): resolve overriding with different method signature
  public serializeNew(withGroups: boolean = false): ChildContract {
    const data: ChildContract = {
      first_name: this.name,
      last_name: this.surname,
      gender: this.gender,
      birth_date: DateHelper.toStringDateOnly(this.birthDate),
    };
    if (withGroups) data.set_groups = this.groups.map((g) => g.uuid);
    return data;
  }

  /**
   * Add group to this child.
   *
   * @param group
   */
  public addGroup(group: Group): void {
    const index: number = this.groups.findIndex((g) => g.uuid === group.uuid);
    if (index === -1) this.groups.push(group);
  }

  /**
   * Remove group from this child.
   *
   * @param group
   */
  public removeGroup(group: Group): void {
    const index: number = this.groups.findIndex((g) => g.uuid === group.uuid);
    if (index !== -1) this.groups.splice(index, 1);
  }
}
