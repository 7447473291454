import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreLegacyModule } from '@isophi/core-legacy';
import { TeachersSharedModule } from '@isophi/teachers-shared';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxSkeletonModule } from 'ngx-skeleton';

import { NotFoundComponent } from './components/not-found/not-found.component';
import { PrepareLinksPipe } from './pipes/prepare-links.pipe';

@NgModule({
  imports: [
    CommonModule,
    CoreLegacyModule,
    TeachersSharedModule,
    NgbModule,
    FormsModule,
    RouterModule,
    NgSelectModule,
    TranslocoModule
  ],
  declarations: [NotFoundComponent, PrepareLinksPipe],
  exports: [TeachersSharedModule, RouterModule, NgbModule, NgxSkeletonModule, TranslocoModule, PrepareLinksPipe],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
