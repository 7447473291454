import { Category } from '@isophi/core-legacy';
import { Child } from './child.model';
import { Test } from './test.model';

import { MultireportChildResultContract } from '../contracts/multireport-child-result.contract';
import { Evaluation } from './evaluation.model';
import { MultireportCategoryResult } from './multireport-category-result';

export class MultireportChildResult {
  public constructor(
    public tests: Test[] | null,
    public child: Child | null,
    public evaluations: Evaluation[] | null,
    public categoryResults: MultireportCategoryResult[] | null
  ) {}

  /**
   * Deserialize JSON to typescript object.
   */
  public static deserialize(
    child: Child | null,
    tests: Test[],
    categories: Category[],
    data: MultireportChildResultContract
  ): MultireportChildResult {
    const evaluations = data.evaluations.map((e) => Evaluation.deserialize(e));

    return new MultireportChildResult(
      tests,
      child,
      evaluations,
      data.category_results.map((categoryResult) =>
        MultireportCategoryResult.deserialize(
          categoryResult,
          categories.find((category) => category.uuid === categoryResult.category_uuid),
          tests.filter((test) => categoryResult.test_results.map((testResult) => testResult.test_uuid).includes(test.uuid)),
          evaluations
        )
      )
    );
  }
}
