import * as Factory from 'factory.ts';

import { Parent } from '../../models';
import { BaseFactory } from './base.factory';

export interface IParent {
  id: number;
  is_active: boolean;
}

export const randomParentDataFactory = Factory.Sync.makeFactory<IParent>({
  id: Factory.each((i) => i),
  is_active: true
});

export const parentDataFactory = Factory.Sync.makeFactory<IParent>({
  id: 1,
  is_active: true
});

export class ParentFactory extends BaseFactory<IParent, Parent> {
  constructor() {
    super();
    this.dataFactory = parentDataFactory;
    this.randomDataFactory = randomParentDataFactory;
    this.classRef = Parent;
  }
}
