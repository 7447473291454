import { Component, Input, OnInit } from '@angular/core';
import { LicenseGeneralFeature, LicenseProductType, LicenseService, LicenseTestFeature } from '@isophi/core-legacy';
import { combineLatest, Observable } from 'rxjs';

import { RouterLinkFactory } from '../../../core/router/router-link.factory';
import { SubNav } from '../../../core/services/subnav.service';
import { MaterialService } from '../../dashboard/services/material.service';

@Component({
  selector: 'app-subnav',
  templateUrl: './subnav.component.html',
  styleUrls: ['./subnav.component.scss']
})
export class SubnavComponent implements OnInit {
  @Input() showSubNav: SubNav;

  subNavEnum: typeof SubNav = SubNav;

  licenses$: Observable<Record<string, boolean>>;

  constructor(
    public links: RouterLinkFactory,
    private licenseService: LicenseService,
    protected materialService: MaterialService
  ) {}

  ngOnInit(): void {
    this.initLicenses();
  }

  isMaterialData(): boolean {
    return this.materialService.checkDiagnosticData();
  }

  isEducationalData(): boolean {
    return this.materialService.checkEducationalData();
  }

  /**
   * Init licenses for this component.
   * Based on them some navigation items are shown/hidden.
   */
  private initLicenses(): void {
    this.licenses$ = combineLatest({
      // Check if can manage children
      childrenManagement: this.licenseService.hasAnyProductWithFeature(
        LicenseProductType.GENERAL,
        LicenseGeneralFeature.CHILDREN_MANAGEMENT
      ),
      // Check concerning the group results
      groupReports: this.licenseService.hasAnyProductWithFeature(LicenseProductType.TEST, LicenseTestFeature.ONLINE_REPORT_GROUP),
      sortedGroupReports: this.licenseService.hasAnyProductWithFeature(
        LicenseProductType.TEST,
        LicenseTestFeature.ONLINE_REPORT_GROUP_SORTED
      ),
      groupReportsZ: this.licenseService.hasAnyProductWithFeature(LicenseProductType.TEST_Z, LicenseTestFeature.ONLINE_REPORT_GROUP),
      sortedGroupReportsZ: this.licenseService.hasAnyProductWithFeature(
        LicenseProductType.TEST_Z,
        LicenseTestFeature.ONLINE_REPORT_GROUP_SORTED
      ),
      // Check if there are any support materials, videos etc. for the products user has
      generalSupport: this.licenseService.hasAnyProductWithFeature(LicenseProductType.GENERAL, LicenseGeneralFeature.GENERAL_SUPPORT),
      testSupport: this.licenseService.hasAnyProductWithFeature(LicenseProductType.TEST, LicenseTestFeature.TEST_SUPPORT),
      testSupportZ: this.licenseService.hasAnyProductWithFeature(LicenseProductType.TEST_Z, LicenseTestFeature.TEST_SUPPORT),
    });
  }

}
