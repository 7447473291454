import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { LicenseProductType } from '../../../enums';
import { LicenseService } from '../../../services';

/**
 * Mock class for licenses in unit tests.
 *
 * All method calls for licenses can be set by string keys in this.licenses.
 * All methods convert self arguments into string key in this way:
 * - All arguments are separated by semicolon
 * - Array of strings is converted into string of values,
 *   where values are separated by comma
 * - Boolean value is converted into string 'true' or 'false'
 *
 * Generated key is then used to find value in this.licenses,
 * if value is found, value is returned, else it returns this.hasLicense value.
 *
 * Every method has also own calls counter, which can be used to check how many times
 * was each method called.
 *
 * Examples:
 * ---------
 * - hasProductWithFeature('test1', 'fill_test') -> 'test1;fill_test'
 * - hasProduct('test1', true) -> 'test1;true'
 * - hasProductsWithFeature(['test1', 'test2', 'test3'], 'fill_test', true) -> 'test1,test2,test3;fill_test;true'
 *
 */
@Injectable({
  providedIn: 'root'
})
export class LicenseServiceMock extends LicenseService {
  public licenses: Record<string, boolean> = {};

  public hasLicense = true;

  public hasProductWithFeatureCounter = 0;

  public hasProductCounter = 0;

  public hasAnyProductCounter = 0;

  public hasProductsCounter = 0;

  public hasProductsWithFeatureCounter = 0;

  public hasAnyProductWithFeatureCounter = 0;

  public hasProductWithFeature(productCodename: string, featureCodename: string): Observable<boolean> {
    this.hasProductWithFeatureCounter += 1;
    const key = `${productCodename};${featureCodename}`;
    if (key in this.licenses) return of(this.licenses[key]);
    return of(this.hasLicense);
  }

  public hasProduct(productCodename: string, anyFeatureActive: boolean = true): Observable<boolean> {
    this.hasProductCounter += 1;
    const anyFeatureActiveStr = anyFeatureActive ? 'true' : 'false';
    const key = `${productCodename};${anyFeatureActiveStr}`;
    if (key in this.licenses) return of(this.licenses[key]);
    return of(this.hasLicense);
  }

  public hasAnyProduct(productType: LicenseProductType, anyFeatureActive: boolean = true): Observable<boolean> {
    this.hasAnyProductCounter += 1;
    const anyFeatureActiveStr = anyFeatureActive ? 'true' : 'false';
    const key = `${productType};${anyFeatureActiveStr}`;
    if (key in this.licenses) return of(this.licenses[key]);
    return of(this.hasLicense);
  }

  public hasProducts(productCodenames: string[], anyFeatureActive: boolean = true): Observable<boolean> {
    this.hasProductsCounter += 1;
    const productCodenamesStr = productCodenames.join(',');
    const anyFeatureActiveStr = anyFeatureActive ? 'true' : 'false';
    const key = `${productCodenamesStr};${anyFeatureActiveStr}`;
    if (key in this.licenses) return of(this.licenses[key]);
    return of(this.hasLicense);
  }

  public hasProductsWithFeature(productCodenames: string[], featureCodename: string, featureActive: boolean = true): Observable<boolean> {
    this.hasProductsWithFeatureCounter += 1;
    const productCodenamesStr = productCodenames.join(',');
    const featureActiveStr = featureActive ? 'true' : 'false';
    const key = `${productCodenamesStr};${featureCodename};${featureActiveStr}`;
    if (key in this.licenses) return of(this.licenses[key]);
    return of(this.hasLicense);
  }

  public hasAnyProductWithFeature(
    productType: LicenseProductType,
    featureCodename: string,
    featureActive: boolean = true
  ): Observable<boolean> {
    this.hasAnyProductWithFeatureCounter += 1;
    const featureActiveStr = featureActive ? 'true' : 'false';
    const key = `${productType};${featureCodename};${featureActiveStr}`;
    if (key in this.licenses) return of(this.licenses[key]);
    return of(this.hasLicense);
  }
}
