/**
 * Service to manage offline auth.
 */
import { Injectable } from '@angular/core';

import { Password } from '../../models';

import crypto from 'crypto-js';
import base64 from 'crypto-js/enc-base64';
import PBKDF2 from 'crypto-js/pbkdf2';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  /**
   * Check if cleanPassword equals to data in password.
   *
   * @param cleanPassword
   * @param password
   */
  public checkPassword(cleanPassword, password: Password): boolean {
    if (password.algorithm !== 'pbkdf2_sha256') {
      throw new TypeError('Unsupported cryptographic algorithm.');
    }

    const config = {
      keySize: 8,
      iterations: password.iterations,
      hasher: crypto.algo.SHA256,
    };
    const passwordCheck = PBKDF2(cleanPassword, password.salt, config);
    return password.hash === base64.stringify(passwordCheck);
  }
}
