import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PopUpType } from '../../../../enums/pop-up-type.enum';

@Component({
  selector: 'app-pop-up-dialog',
  templateUrl: './pop-up-dialog.component.html',
  styleUrls: ['./pop-up-dialog.component.scss'],
  // For being able to use css styles in "innerHTML"
  encapsulation: ViewEncapsulation.None,
})
export class PopUpDialogComponent implements OnInit {
  @Input()
  public data: any;

  public popUpType: typeof PopUpType;

  public inputValue = '';

  public selectValue: any | null = null;

  public inputValid = true;

  public constructor(public modal: NgbActiveModal) {
    this.popUpType = PopUpType;
  }

  public ngOnInit(): void {
    if (Object.prototype.hasOwnProperty.call(this.data, 'defaultValue')) {
      this.inputValue = this.data.defaultValue;
    }

    if (this.data.popUpType === PopUpType.SELECT) {
      this.selectValue = this.data.options[0];
    }
  }

  public dismissModal(): void {
    this.modal.dismiss();
  }

  public closeModal(result: boolean): void {
    this.modal.close(result);
  }

  public okPromptButton(): void {
    if (this.data.popUpType === PopUpType.PROMPT) {
      this.processInput();
    } else if (this.data.popUpType === PopUpType.SELECT) {
      this.processSelect();
    } else {
      throw new Error(`Unknown pop up type: ${this.popUpType}`);
    }
  }

  protected processInput(): void {
    const validationRegex = this.data.validationRegex;

    if (validationRegex !== null && this.inputValue.match(validationRegex) === null) {
      this.inputValid = false;
      return;
    }

    this.modal.close(this.inputValue);
  }

  protected processSelect(): void {
    this.modal.close(this.selectValue);
  }
}
