import { ParentContract } from './parent.contract';
import { PasswordContract } from './password.contract';
import { TeacherContract } from './teacher.contract';

/**
 * Serialized User fields.
 *
 * @field teacher is presented on demand
 * @field parent is presented on demand
 * @field password_data is presented on demand
 */
export interface UserContract {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  group_string: string;
  teacher?: TeacherContract;
  parent?: ParentContract;
  password_data?: PasswordContract;
}
