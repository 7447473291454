import { Injectable } from '@angular/core';
import { User } from '../../../models';
import { AuthService } from '../../../services';
import { IStorage } from '../../../storages';

@Injectable({
  providedIn: 'root',
})
export class AuthServiceMock extends AuthService {
  public accessToken: string | null = 'token';

  public storedToken: string | null = null;

  public authenticationError: Error | null = null;

  public authenticatedUser: User | null = null;

  public accessTokenValid = true;

  public storeToken(_storage: IStorage): void {
    this.storedToken = this.accessToken;
  }

  public restoreToken(_storage: IStorage): void {
    this.accessToken = this.storedToken;
  }

  public authenticateByAccessToken(): Promise<void> {
    if (this.authenticationError) return Promise.reject(this.authenticationError);
    this.loggedUser = this.authenticatedUser;
    return Promise.resolve();
  }

  public authenticate(_username: string, _password: string, _onlineLogin: boolean = true): Promise<void> {
    if (this.authenticationError) return Promise.reject(this.authenticationError);
    this.loggedUser = this.authenticatedUser;
    return Promise.resolve();
  }

  public isAccessTokenValid(): Promise<boolean> {
    return Promise.resolve(this.accessTokenValid);
  }
}
