import { Injectable } from '@angular/core';
import { AuthService, IsophiCoreService, IStorage, LicenseService, LocalStorage } from '@isophi/core-legacy';

import { environment } from '../../../environments/environment';
import { KindergartenService } from './kindergarten.service';

@Injectable({
  providedIn: 'root',
})
export class InitService {
  protected storage: IStorage;

  public constructor(
    private isophiCoreService: IsophiCoreService,
    private authService: AuthService,
    private kindergartenService: KindergartenService,
    private licenseService: LicenseService
  ) {
    this.storage = new LocalStorage();
  }

  /**
   * Init application.
   */
  public async initApplication(): Promise<void> {
    // init isophi-core library
    this.isophiCoreService.setUpRequiredFields(
      environment.common.version,
      environment.common.appKeyword,
      environment.client_id,
      environment.teacherAPI
    );
    this.isophiCoreService.setField('auth-api', environment.authAPI);
    this.isophiCoreService.setField('auth-extra-models', 'teacher');
    this.isophiCoreService.setField('license-api', environment.licenseAPI);

    // restore access token and try to load user + kindergarten
    this.authService.restoreToken(this.storage);
    await this.authService
      .authenticateByAccessToken()
      .then(() => this.kindergartenService.downloadLoggedKindergarten())
      .then(() => (this.licenseService.licenseSubjectUuid = this.kindergartenService.loggedKindergarten.uuid))
      .catch(() => null);
  }
}
