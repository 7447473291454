import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CategoryService, FileService } from '@isophi/core-legacy';
import { ListResponseContract, Material, MaterialContract, MaterialProduct, MaterialProductContract } from '@isophi/teachers-shared';
import * as _ from 'lodash';
import { BehaviorSubject, forkJoin, map, Observable, shareReplay } from 'rxjs';

import { environment } from '../../../../environments/environment';

export enum SortType {
  DIAGNOSTIC = 'diagnostic',
  EDUCATIONAL = 'educational',
}

@Injectable({
  providedIn: 'root',
})
export class MaterialService {
  private diagnosticProductsSubject$: BehaviorSubject<MaterialProduct[]> = new BehaviorSubject(null);

  private educationalProductsSubject$: BehaviorSubject<MaterialProduct[]> = new BehaviorSubject(null);

  private diagnosticMaterialsSubject$: BehaviorSubject<Material[][]> = new BehaviorSubject(null);

  private educationalMaterialsSubject$: BehaviorSubject<Material[][]> = new BehaviorSubject(null);

  diagnosticProducts$: Observable<MaterialProduct[]> = this.diagnosticProductsSubject$.asObservable();

  educationalProducts$: Observable<MaterialProduct[]> = this.educationalProductsSubject$.asObservable();

  diagnosticMaterials$: Observable<Material[][]> = this.diagnosticMaterialsSubject$.asObservable();

  educationalMaterials$: Observable<Material[][]> = this.educationalMaterialsSubject$.asObservable();

  constructor(private http: HttpClient, private categoryService: CategoryService, private fileService: FileService) {}

  getManualMaterials(): Observable<Material[]> {
    const url = `${environment.materialAPI}/document-sets/?set_type=manual`;

    return forkJoin([this.http.get<ListResponseContract<MaterialContract>>(url), this.categoryService.categories$]).pipe(
      map(([materials, categories]) => materials.results.map((materialData) => Material.deserialize(materialData, categories))),
      shareReplay(1)
    );
  }

  getDownloadableMaterials(sortType: string): Observable<Material[][]> {
    const url = `${environment.materialAPI}/document-sets/?set_type=downloadable_material&sort_type=${sortType}`;

    return forkJoin([this.http.get<ListResponseContract<MaterialContract>>(url), this.categoryService.categories$]).pipe(
      map(([materials, categories]) => {
        const data = materials.results.map((materialData) => Material.deserialize(materialData, categories));

        return _.map(_.groupBy(data, 'productId'));
      }),
      shareReplay(1)
    );
  }

  getProducts(sortType: string): Observable<MaterialProduct[]> {
    const url = `${environment.materialAPI}/document-sets/products/?sort_type=${sortType}`;

    return this.http.get<MaterialProductContract[]>(url).pipe(
      map((response) => response.map((product) => MaterialProduct.deserialize(product))),
      shareReplay(1)
    );
  }

  downloadAllMaterials(uuid: string): Observable<void> {
    const url = `${environment.materialAPI}/document-sets/${uuid}/download_all/`;

    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          const contentDispositionHeader = response.headers.get('Content-Disposition');
          const filename = /filename="?([^"]+)"?/i.exec(contentDispositionHeader)[1];
          return this.fileService.downloadFile(response.body, filename);
        })
      );
  }

  checkDiagnosticData(): boolean {
    return this.diagnosticProductsSubject$.value?.length > 0 && this.diagnosticMaterialsSubject$.value?.length > 0;
  }

  checkEducationalData(): boolean {
    return this.educationalProductsSubject$.value?.length > 0 && this.educationalMaterialsSubject$.value?.length > 0;
  }

  setDiagnosticProductsSubject(val: MaterialProduct[]) {
    this.diagnosticProductsSubject$.next(val);
  }

  setEducationalProductsSubject(val: MaterialProduct[]) {
    this.educationalProductsSubject$.next(val);
  }

  setDiagnosticMaterialsSubject(val: Material[][]) {
    this.diagnosticMaterialsSubject$.next(val);
  }

  setEducationalMaterialsSubject(val: Material[][]) {
    this.educationalMaterialsSubject$.next(val);
  }
}

