import { Injectable, TemplateRef } from '@angular/core';

export interface ToastOptions {
  delay?: number;
  classname?: string;
  autohide?: boolean;
}

export interface Toast extends ToastOptions {
  textOrTpl: string | TemplateRef<any>;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: Toast[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: ToastOptions = { autohide: true }) {
    this.toasts.push({ textOrTpl, ...options });
  }

  info(textOrTpl: string | TemplateRef<any>, title?: string) {
    this.show(textOrTpl);
  }

  success(textOrTpl: string | TemplateRef<any>, title?: string) {
    this.show(textOrTpl, { classname: 'bg-success text-light', autohide: true });
  }

  warning(textOrTpl: string | TemplateRef<any>, title?: string) {
    this.show(textOrTpl, { classname: 'bg-warning text-dark', autohide: true });
  }

  error(textOrTpl: string | TemplateRef<any>, title?: string, autohide = true) {
    this.show(textOrTpl, { classname: 'bg-danger text-light', autohide });
  }

  remove(toast: Toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }
}
