import { Inject, Injectable } from '@angular/core';
import { getBrowserLang, TranslocoService } from '@ngneat/transloco';
import { TranslocoPersistLangService } from '@ngneat/transloco-persist-lang';
import { AppConfig, APP_CONFIG } from '../utils/app.config';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private persist: TranslocoPersistLangService,
    private translocoService: TranslocoService
  ) {}

  initLanguage() {
    if (this.appConfig.i18n) {
      const cachedLang = this.persist.getCachedLang();
      const browserLang = getBrowserLang();
      if (!cachedLang) {
        if (browserLang === 'cs') {
          this.translocoService.setActiveLang('cs');
        } else {
          this.translocoService.setActiveLang('en');
        }
      }
    }
  }
}
