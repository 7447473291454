import { MenuTargetContract } from "../contracts/menu-target.contract";

export class MenuTarget {
  constructor(
    public name: string,
    public targetType: string,
    public promo?: boolean,
    public codename?: string,
    public filteredProductType?: string,
    public productId?: number
  ) {}

  /**
  * Deserialize JSON to typescript object.
  *
  * @param data
  */
  public static deserialize(data: MenuTargetContract): MenuTarget {
    return new MenuTarget(
      data.name,
      data.target_type,
      data.promo,
      data.codename,
      data.filtered_product_type,
      data.product_id
    )
  }
}