<div *ngIf="warningText" [innerHTML]="warningText" class="alert alert-warning" role="alert"></div>

<h2 [innerHTML]="infoTitle"></h2>
<p [innerHTML]="infoText"></p>

<h2 *ngIf="previewTitle && previewImgPath" [innerHTML]="previewTitle"></h2>

<div *ngIf="previewImgPath" class="row">
  <div class="col">
    <img src="{{ previewImgPath }}" />
  </div>
  <div *ngIf="previewImgPath2" class="col">
    <img src="{{ previewImgPath2 }}" />
  </div>
</div>
