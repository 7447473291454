import { Category, Child } from '@isophi/core-legacy';
import { ProblematicCategoryContract } from '../contracts';

/**
 * One psychological category and children, who have problems in this category.
 */
export class ProblematicCategory {
  public children: Array<Child> = [];

  public constructor(public category: Category, public maxScore: number, public score: number, public percent: number) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param category
   */
  public static deserialize(data: ProblematicCategoryContract, category: Category): ProblematicCategory {
    return new ProblematicCategory(category, data.max_score, data.score, data.percent);
  }

  public addChild(child: Child | undefined): void {
    if (!child) return;
    this.children.push(child);
  }
}
