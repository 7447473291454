/* eslint-disable @angular-eslint/directive-selector */
import { NgForOf } from '@angular/common';
import { Directive, Host, Input } from '@angular/core';

@Directive({
  selector: '[ngForTrackByProperty]'
})
export default class TrackByPropertyDirective {
  private innerPropertyName = '';

  public constructor(@Host() private readonly ngFor: NgForOf<any>) {
    this.ngFor.ngForTrackBy = (_: number, item: any) => (this.innerPropertyName ? item[this.innerPropertyName] : item);
  }

  @Input('ngForTrackByProperty')
  public set propertyName(value: string | null) {
    this.innerPropertyName = value ?? '';
  }
}
