import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Menu } from '@isophi/teachers-shared';
import { Observable } from 'rxjs';

import { ProductType } from '../../child/components/list-filter/list-filter.component';
import { MenuService, MenuType } from '../services/menu.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
  menuData$: Observable<Menu[]>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  ProductType = ProductType;

  constructor(private menuService: MenuService) {
    this.menuData$ = this.menuService.getMenu(MenuType.SIDE);
  }

  preventMenuClose(event) {
    if (event.target.className.includes('accordion-button')) {
      event.stopPropagation();
    }
  }
}
