import moment from 'moment';

/**
 * Basic date represented by day, month and year numbers.
 */
export class BasicDate {
  constructor(public day?: number, public month?: number, public year?: number) {}

  /**
   * Return child age in days.
   */
  public get ageInDays(): number {
    const birthDate = moment(this.toNativeDate());
    const now = moment();
    return now.diff(birthDate, 'days');
  }

  /**
   * Convert BasicDate to JS Date.
   *
   * If data are not valid date, it returns null.
   */
  public toNativeDate(): Date | null {
    if (!this.isValid()) return null;
    const date = new Date(`${this.year}-${this.month}-${this.day}`);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  /**
   * Convert BasicDate to Moment date.
   */
  public toMomentDate(): moment.Moment {
    return moment(`${this.year}-${this.month}-${this.day}`, 'YYYY-M-D', true);
  }

  /**
   * Return true/false if day, month and year create valid date.
   */
  public isValid(): boolean {
    return this.toMomentDate().isValid();
  }

  /**
   * Return true/false if day, month and year fields are defined.
   */
  public isDefined(): boolean {
    return !!this.day && !!this.month && !!this.year;
  }
}
