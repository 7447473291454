import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SupportedFileTypeService {
  doc = '.doc';

  pdf = '.pdf';

  xlsx = '.xlsx';

  xls = '.xls';

  csv = '.csv';

  image = 'image/png, image/jpeg, image/svg, image/jpg';
}
