/**
 * @filed title on request as name
 */
export interface QuestionnaireContract {
  id: number;
  uuid: string;
  name?: string;
  short_title?: string;
  color?: string;
  product_codename?: string;
}
