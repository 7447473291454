import { Injectable } from '@angular/core';
import { Report } from '@isophi/teachers-shared';

@Injectable({
  providedIn: 'root',
})
export class RouterLinkFactory {
  public homepage(): (string | number)[] {
    return this.dashboardIndex();
  }

  public login(): (string | number)[] {
    return ['/login'];
  }

  public logout(): (string | number)[] {
    return ['/login', 'user-logout'];
  }

  public forgottenPassword(): (string | number)[] {
    return ['/login', 'forgotten-password'];
  }

  public changePassword(): (string | number)[] {
    return ['/user', 'change-password'];
  }

  public dashboardIndex(): (string | number)[] {
    return ['/dashboard'];
  }

  public materialsDiagnostic(): (string | number)[] {
    return ['/dashboard', 'materials', 'diagnostic'];
  }

  public materialsEducational(): (string | number)[] {
    return ['/dashboard', 'materials', 'educational'];
  }

  public manuals(): (string | number)[] {
    return ['/dashboard', 'manuals'];
  }

  public editTeacher($teacherId: number): (string | number)[] {
    return ['/kindergarten', 'teachers', $teacherId];
  }

  public createTeacher(): (string | number)[] {
    return ['/kindergarten', 'teachers', 'create'];
  }

  public teacherList(): (string | number)[] {
    return ['/kindergarten', 'teachers'];
  }

  public childList(): (string | number)[] {
    return ['/child', 'list'];
  }

  public groupList(): (string | number)[] {
    return ['/child', 'groups'];
  }

  public childArchive(): (string | number)[] {
    return ['/child', 'archive'];
  }

  public createChild(): (string | number)[] {
    return ['/child', 'new'];
  }

  /**
   * Prepared for the eventuality of having multiple report types.
   *
   * @param report which should be shown
   * @returns the route
   */
  public report(report: Report): (string | number)[] {
    return ['/reports', report.keyword];
  }

  public groupReportProblematicCategories(): string[] {
    return ['/reports', 'problematic-categories'];
  }

  public groupSortedReport(): string[] {
    return ['/reports', 'sorted'];
  }

  public childReport(uuid: string): (string | number)[] {
    return ['/reports', 'individual', uuid];
  }

  public multiReport(uuid: string): (string | number)[] {
    return ['/reports', 'multi', uuid];
  }

  public games(): (string | number)[] {
    return ['/games', 'play'];
  }
}
