import { QuestionnaireContract } from '../contracts/questionnaire.contract';

/**
 * Class representing questionnaire.
 */
export class Questionnaire {
  public title: string;

  public constructor(
    public id: number,
    public uuid: string,
    public productCodename: string,
    public shortTitle?: string,
    public color?: string
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: QuestionnaireContract): Questionnaire {
    const shortTitle = data.short_title === undefined ? 'assets/img/logo/iSophiR.png' : data.short_title;

    const questionnaire = new Questionnaire(data.id, data.uuid, data.product_codename, shortTitle, data.color);

    if (data.name) {
      questionnaire.title = data.name;
    }

    return questionnaire;
  }

  /**
   * Return Test object serialized to basic javascript types.
   */
  public serialize(): QuestionnaireContract {
    const data: QuestionnaireContract = {
      id: this.id,
      uuid: this.uuid,
      name: this.title,
      product_codename: this.productCodename,
      color: this.color,
      short_title: this.shortTitle
    };
    return data;
  }
}
