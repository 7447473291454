import { Password } from '../../../models';
import { SecurityService } from '../../../services';

export class SecurityServiceMock extends SecurityService {
  public passwordIsCorrect = true;

  public checkPassword(_cleanPassword, _password: Password): boolean {
    return this.passwordIsCorrect;
  }
}
