/**
 * Evaluation of one unit (category/test/questionnaire/...)
 *
 * Colors are in hexadecimal format: "#xxxxxx"
 */
export interface EvaluationContract {
  id?: number | null;
  title: string;
  code: number;
  text_color: string;
  background_color: string;
  max_percent?: number | null;
  min_percent?: number | null;
  max_point?: number | null;
  min_point?: number | null;
}
