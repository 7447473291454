<ng-container *transloco="let t">
  <div class="container-sm">
    <a class="back-button" [routerLink]="links.teacherList()">
      <img src="assets/img/arrow-back.svg" [alt]="t('kindergarten/back')" />
      <span>{{ t('kindergarten/back') }}</span>
    </a>
    <h3 class="fw-bold mb-7">{{ t('kindergarten/editData') }}</h3>
    <div class="row">
      <div class="col">
        <app-user-form
          *ngIf="userDto$ | withLoader | async as userDto"
          [selfEdit]="loggedUser?.teacher.id === teacherId"
          [showPasswordField]="false"
          [userDto]="userDto"
          [disabled]="false"
          (submitForm)="onSubmit($event)"
          [submitButtonText]="t('kindergarten/editTeacher')"
        ></app-user-form>
      </div>
    </div>
  </div>
</ng-container>
