import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Menu, MenuContract } from '@isophi/teachers-shared';
import { map, Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

export enum MenuType {
  TOP = 'top',
  SIDE = 'side'
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private http: HttpClient) {}

  getMenu(type: MenuType): Observable<Menu[]> {
    const url = `${environment.menuAPI}/menu/t_app_menu/?menu_type=${type}`;

    return this.http.get<MenuContract[]>(url).pipe(
      map((response) => response.map((val) => Menu.deserialize(val)))
    );
  }
}
