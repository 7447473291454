/**
 * Evaluation of one unit (category/test/questionnaire/...)
 *
 * Colors are in hexadecimal format: "#xxxxxx"
 */
import { UserContract } from '@isophi/core-legacy';

export interface TeacherContract {
  id: number;
  is_active: boolean;
  groups: string[];
  user_id: number;
  user: UserContract;
}
