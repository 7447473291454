import { CategoryContract } from '../../contracts';

export class Category {
  constructor(
    public id: number,
    public uuid: string,
    public name: string,
    public activeIconUrl: string,
    public inactiveIconUrl: string,
    public color: string
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: CategoryContract): Category {
    return new Category(data.id, data.uuid, data.name, data.active_icon_url, data.inactive_icon_url, data.color);
  }

  /**
   * Return category object serialized to basic javascript types.
   */
  public serialize(): CategoryContract {
    return {
      id: this.id,
      uuid: this.uuid,
      name: this.name,
      active_icon_url: this.activeIconUrl,
      inactive_icon_url: this.inactiveIconUrl,
      color: this.color
    };
  }

  /**
   * It replaces category data with data of other category.
   *
   * @param other
   */
  public replaceData(other: Category) {
    this.id = other.id;
    this.uuid = other.uuid;
    this.name = other.name;
    this.activeIconUrl = other.activeIconUrl;
    this.inactiveIconUrl = other.inactiveIconUrl;
    this.color = other.color;
  }
}
