import { Component, Input } from '@angular/core';

/**
 * Reusable component for chart hint.
 */
@Component({
  selector: 'isophi-chart-hint',
  templateUrl: './chart-hint.component.html',
  styleUrls: ['./chart-hint.component.css']
})
export class ChartHintComponent {
  @Input() public evaluations: any[];

  @Input() public theme: 'diag' | 'default' = 'default';

  @Input() public text?: string;
}
