import moment from 'moment';

import { DateHelper } from '../../misc/date.helper';
import { BasicDate } from '../general/basic-date.model';
import { IChildData } from '../types';

export class Child {
  public id: number = null;

  public uuid: string | null = null;

  public name: string | null = null;

  public surname: string | null = null;

  public gender: string | null = null;

  public birthDate: Date | null = null;

  public revision = 0;

  public synced = false;

  /**
   * Return full name of the child.
   */
  public get fullName(): string {
    return this.surname + ' ' + this.name;
  }

  /**
   * Return child age in days.
   */
  public get ageInDays(): number {
    const birthDate = moment(this.birthDate);
    const now = moment();
    return now.diff(birthDate, 'days');
  }

  /**
   * Returns child age in years.
   */
  public get age(): number {
    const birthDate = moment(this.birthDate);
    const now = moment();
    return now.diff(birthDate, 'years');
  }

  /**
   * Return child age as 3 numbers (years, months, days).
   */
  public get ageParts(): Array<number> {
    const birthDate = moment(this.birthDate);
    const now = moment();

    const years = now.diff(birthDate, 'year');
    birthDate.add(years, 'years');
    const months = now.diff(birthDate, 'months');
    birthDate.add(months, 'months');
    const days = now.diff(birthDate, 'days');

    return [years, months, days];
  }

  /**
   * Create new Child with ID.
   *
   * Used this method when deserializing data from server.
   *
   * @param id
   * @param uuid
   * @param firstName
   * @param lastName
   * @param gender
   * @param birthDate
   * @param revision
   */
  public static createWithId(id, uuid, firstName, lastName, gender, birthDate, revision = 0) {
    const child = Child.create(uuid, firstName, lastName, gender, birthDate, revision);
    child.id = id;
    return child;
  }

  /**
   * Create new Child without ID.
   *
   * Used this method when creating Child in the DAD app.
   *
   * @param uuid
   * @param firstName
   * @param lastName
   * @param gender
   * @param birthDate
   * @param revision
   */
  public static create(uuid, firstName, lastName, gender, birthDate, revision = 0) {
    const child = new Child();
    child.uuid = uuid;
    child.name = firstName;
    child.surname = lastName;
    child.gender = gender;
    child.birthDate = birthDate;
    child.birthDate.setHours(0, 0, 0, 0); // Ensure time set to zero.
    child.revision = revision;
    return child;
  }

  /**
   * Deserializer child data into new Child.
   *
   * @param data
   */
  public static deserialize(data: any): Child {
    return Child.createWithId(
      data.id,
      data.uuid,
      data.first_name,
      data.last_name,
      data.gender,
      DateHelper.parse(data.birth_date),
      data.revision
    );
  }

  /**
   * Return child object serialized to basic javascript types.
   */
  public serialize(): IChildData {
    return {
      id: this.id,
      uuid: this.uuid,
      first_name: this.name,
      last_name: this.surname,
      birth_date: DateHelper.toStringDateOnly(this.birthDate),
      gender: this.gender,
      revision: this.revision,
      synced: this.synced,
    };
  }

  /**
   * Replace data from other child to this one.
   *
   * If skipIdentity is true, it skip data identifying child, that is ID, UUID and revision.
   *
   * @param otherChild
   * @param skipIdentity
   */
  public replaceData(otherChild: Child, skipIdentity: boolean = true) {
    this.name = otherChild.name;
    this.surname = otherChild.surname;
    if (otherChild.birthDate instanceof Date) {
      this.birthDate = new Date(otherChild.birthDate.getTime());
    }
    this.gender = otherChild.gender;

    if (!skipIdentity) {
      this.id = otherChild.id;
      this.uuid = otherChild.uuid;
      this.revision = otherChild.revision;
      this.synced = otherChild.synced;
    }
  }

  /**
   * Return birth date as BasicDate
   */
  public birthDateToBasicDate(): BasicDate {
    return new BasicDate(this.birthDate.getDate(), this.birthDate.getMonth() + 1, this.birthDate.getFullYear());
  }

  /**
   * Returns true if children are similar otherwise returns false.
   *
   * Similar children have same birth date and surname.
   */
  public compare(other: Child) {
    return this.surname === other.surname && this.birthDate.getTime() === other.birthDate.getTime();
  }

  /**
   * Mark this entity as 'dirty', i.e. it is NOT synced with server and should be sync during next synchronization.
   */
  public markDirty() {
    this.synced = false;
  }
}
