/**
 * Service to manage children.
 */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { Child } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class ChildService {
  public childList: Array<Child> = new Array<Child>();

  public childListChangedSubject: Subject<null> = new Subject();

  public selectedChild: Child | null = null;

  /**
   * Take response from server with children data and deserialize it.
   *
   * @param data
   */
  public deserializeChildList(data: any) {
    for (const childData of data.results) {
      const child = Child.deserialize(childData);
      this.childList.push(child);
    }
    this.childListChanged();
  }

  /**
   * Call this method, when any Child in the Application was changed or added new one.
   */
  public childListChanged() {
    this.sortChildList();
    this.childListChangedSubject.next(null);
  }

  /**
   * Return child or undefined if child is not found.
   *
   * @param childUuid
   */
  public getChild(childUuid: string): Child | undefined {
    return this.childList.find((x) => x.uuid === childUuid);
  }

  /**
   * Add new child to the application. Use this method when CREATING new child.
   *
   * Method DOES NOT check for duplication, you should call getSimilarChildren()
   * if you want to prevent duplicated children.
   *
   * @param child
   * @param generateUUID
   * @return boolean
   */
  public addNewChild(child: Child, generateUUID: boolean = false) {
    if (generateUUID) {
      child.uuid = uuid();
    }

    this.childList.push(child);
    this.childListChanged();

    return true;
  }

  /**
   *  Return similar children.
   *
   *  Skip itself if child already in childList.
   *
   * @param child
   */
  public getSimilarChildren(child: Child): Array<Child> {
    return this.childList.filter((x) => x.uuid !== child.uuid && x.compare(child));
  }

  /**
   * Sort childList by full name.
   */
  private sortChildList() {
    this.childList.sort((a, b) => {
      const nameA = a.surname.toLowerCase() + a.name.toLowerCase();
      const nameB = b.surname.toLowerCase() + b.name.toLowerCase();
      return nameA.localeCompare(nameB);
    });
  }
}
