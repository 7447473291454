import { EvaluationContract } from '.';
import { GroupCategoryResultContract } from '.';
import { GroupChildResultContract } from '.';
import { ProblematicCategoryContract } from '.';

/**
 * Report data for 'problematic-categories' report.
 */
export interface ProblematicCategoriesReportDataContract {
  calculated: boolean;
  category_results: GroupCategoryResultContract[];
  child_average_age: number;
  child_results: GroupChildResultContract[];
  evaluation: EvaluationContract | null;
  percent: number;
  problematic_categories: ProblematicCategoryContract[];
  problematic_percent_limit: number;
  score: number;
  test_uuid: string;
  max_score: number;
}
