import { Group } from './group.model';
import { ReportSettings } from './report-settings.interface';
import { Test } from './test.model';

export class GroupReportSettings implements ReportSettings {
  public constructor(
    public groups: Array<Group>,
    public includeChildrenWithoutGroups: boolean,
    public test: Test,
    public dateFrom: Date,
    public dateTo: Date,
    public ageFromYears: number,
    public ageFromMonths: number,
    public ageToYears: number,
    public ageToMonths: number,
    public kindergartenId?: number,
    public kindergartenUuid?: string,
    public orderBy?: string
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param groups
   * @param test
   */
  public static deserialize(data: any, groups: Array<Group>, test: Test): GroupReportSettings {
    return new GroupReportSettings(
      groups,
      data.include_children_without_groups,
      test,
      data.date_from,
      data.date_to,
      data.age_from_years,
      data.age_from_months,
      data.age_to_years,
      data.age_to_months
    );
  }

  public getGroups(): Array<Group> | null {
    return this.groups;
  }

  /**
   * Return Report object serialized to basic javascript types.
   */
  public serialize(): any {
    return {
      group_uuids: this.groups.map((g) => g.uuid),
      include_children_without_groups: this.includeChildrenWithoutGroups,
      test_uuid: this.test.uuid,
      date_from: this.dateFrom.toISOString().substr(0, 10),
      date_to: this.dateTo.toISOString().substr(0, 10),
      age_from_years: this.ageFromYears,
      age_from_months: this.ageFromMonths,
      age_to_years: this.ageToYears,
      age_to_months: this.ageToMonths,
      kindergarten_id: this.kindergartenId,
      kindergarten_uuid: this.kindergartenUuid,
      order_by: this.orderBy,
    };
  }

  /**
   * Return Report object serialized to basic javascript types without any nested fields.
   */
  public apiSerialize(): any {
    return {
      group_uuids: this.groups.map((g) => g.uuid).join(','),
      include_children_without_groups: this.includeChildrenWithoutGroups,
      test_uuid: this.test.uuid,
      date_from: this.dateFrom.toISOString().substr(0, 10),
      date_to: this.dateTo.toISOString().substr(0, 10),
      age_from_years: this.ageFromYears,
      age_from_months: this.ageFromMonths,
      age_to_years: this.ageToYears,
      age_to_months: this.ageToMonths,
    };
  }
}
