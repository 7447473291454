import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-with-loader',
  templateUrl: './button-with-loader.component.html',
  styleUrls: ['./button-with-loader.component.scss']
})
export class ButtonWithLoaderComponent {
  @Input()
  public loading: boolean;

  @Input()
  public disabled: boolean;

  @Output()
  public buttonClick = new EventEmitter<MouseEvent>();

  public handleClick(event: MouseEvent): void {
    event.stopPropagation();
    this.buttonClick.emit();
  }
}
