import { TestContract } from '../contracts';

/**
 * Class representing test.
 */
export class Test {
  public shortTitle: string;

  public color: string;

  public constructor(
    public id: number,
    public uuid: string,
    public title: string,
    public productCodename: string,
    public minAge: number,
    public maxAge: number,
    public age: number,
    public version: string,
    public etag: string
  ) {}

  /**
   * Return test min age in years.
   */
  public get minAgeYear(): number {
    return Math.floor(this.minAge / 365.24);
  }

  /**
   * Return test min age in months module years (in months).
   */
  public get minAgeMonthMod(): number {
    return Math.floor((this.minAge % 365.24) / 30.44);
  }

  /**
   * Return test max age in years.
   */
  public get maxAgeYear(): number {
    return Math.floor(this.maxAge / 365.24);
  }

  /**
   * Return test max age in months module years (in months).
   */
  public get maxAgeMonthMod(): number {
    return Math.floor((this.maxAge % 365.24) / 30.44);
  }

  /**
   * Return test age in years.
   */
  public get ageYear(): number {
    return Math.floor(this.age / 365.24);
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: TestContract): Test {
    const codename = data.product_codename === undefined ? 'INVALID_CODENAME' : data.product_codename;

    const test = new Test(data.id, data.uuid, data.title, codename, data.min_age, data.max_age, data.age, data.version, data.etag);

    if (data.short_title) {
      test.shortTitle = data.short_title;
    }

    if (data.color) {
      test.color = data.color;
    }

    return test;
  }

  /**
   * Return Test object serialized to basic javascript types.
   */
  public serialize(): TestContract {
    const data: TestContract = {
      id: this.id,
      uuid: this.uuid,
      title: this.title,
      product_codename: this.productCodename,
      min_age: this.minAge,
      max_age: this.maxAge,
      age: this.age,
      version: this.version,
      etag: this.etag,
    };

    if (this.color) data.color = this.color;
    if (this.shortTitle) data.short_title = this.shortTitle;

    return data;
  }
}
