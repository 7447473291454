import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { AuthGuard } from './guards/auth.guard';
import { ManagerGuard } from './guards/manager.guard';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [AuthGuard, ManagerGuard],
})
export class CoreModule {
  public constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('CoreModule should be imported only from AppModule.');
    }
  }
}
