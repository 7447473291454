import { LicenseDatesContract } from './license-dates.contract';

/**
 * Data with licenses for one subject (kindergarten/user/...).
 */
export interface SubjectLicensesContract {
  /**
   * Subject licenses.
   *
   * This field is not present if field changed is present and set to true.
   *
   * Format:
   * {
   *   'product_codename': {
   *     'feature_codename': LicenseDatesContract,
   *     ...
   *   },
   *   ...
   * }
   */
  licenses?: Record<string, Record<string, LicenseDatesContract>>;

  /**
   * true/false if data was changed.
   *
   * This field is present only if request is sent with 'changed_since' query param.
   */
  changed?: boolean;

  /**
   * Products organized by product_type.
   *
   * Contain only products associated with specified subject.
   * This field is not present if field changed is present and set to true.
   *
   * Format:
   * {
   *   'product_type': ['product_codename', 'product_codename', ...],
   *   ...
   * }
   */
  product_types?: Record<string, string[]>;
}
