<div class="text-center">
  <a href="https://www.facebook.com/rozvojditete.cz" target="_blank" class="me-2">
    <i class="fe fe-facebook" aria-hidden="true"></i>
  </a>
  <a href="https://www.instagram.com/rozvojditetecz/" target="_blank" class="me-2">
    <i class="fe fe-instagram" aria-hidden="true"></i>
  </a>
  <a href="https://www.youtube.com/channel/UCNg151GyAQwRNEQs2BUnUKA" target="_blank">
    <i class="fe fe-play" aria-hidden="true"></i>
  </a>
</div>
