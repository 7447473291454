/**
 * Class to hold data for hashed password.
 */
import { IPasswordData } from '../types';

export class Password {
  constructor(public algorithm: string, public iterations: number, public salt: string, public hash: string) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: IPasswordData): Password {
    return new Password(data.algorithm, data.iterations, data.salt, data.hash);
  }

  /**
   * Return password object serialized to basic javascript types.
   */
  public serialize(): IPasswordData {
    return {
      algorithm: this.algorithm,
      iterations: this.iterations,
      salt: this.salt,
      hash: this.hash
    };
  }
}
