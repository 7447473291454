import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDateCSParserFormatter } from '@isophi/core-ui';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';


const COMPONENTS = [];

@NgModule({
  imports: [CommonModule, FormsModule, NgbModule],
  providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateCSParserFormatter }],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class CoreLegacyModule {}
