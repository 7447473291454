import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from '@isophi/core-legacy';

import { EditTeacherComponent } from '../../modules/kindergarten/pages/edit-teacher/edit-teacher.component';
import { RouterLinkFactory } from '../router/router-link.factory';

@Injectable()
export class ManagerGuard implements CanActivate {
  public constructor(private authService: AuthService, private router: Router, private routerLinkFactory: RouterLinkFactory) {}

  public canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const loggedUser = this.authService.loggedUser;

    // Allow logged teacher to edit him/her data:
    if (loggedUser.teacher !== null && route.component === EditTeacherComponent && Number(route.params.id) === loggedUser.teacher.id) {
      return Promise.resolve(true);
    }

    if (!loggedUser.teacher || !loggedUser.teacher.isManager()) {
      this.router.navigate(this.routerLinkFactory.homepage());
      return Promise.resolve(false);
    }

    return Promise.resolve(true);
  }
}
