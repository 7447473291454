import { Component, Input } from '@angular/core';

/**
 * View for the lock icon.
 * Could be used if some functionality is locked (e.g. licenses)
 */
@Component({
  selector: 'isophi-lock-icon',
  templateUrl: './lock-icon.component.html',
})
export class LockIconComponent {
  @Input() public show = false;

  @Input() public small?: boolean = false;

  @Input() public muted?: boolean = false;
}
