/**
 * Class to help work with dates.
 *
 */
export class DateHelper {
  public static readonly DAYS_IN_YEARS = 365.242199;

  /**
   * Convert date string into Date.
   * Format: 'yyyy-mm-dd hh:mm:ss'
   *
   * @param date
   */
  public static parse(date: string) {
    return new Date(date);
  }

  /**
   * Convert Date into string. It is time zone aware.
   * Format: 'yyyy-mm-dd hh:mm:ss'
   *
   * @param date
   */
  public static toString(date: Date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().substr(0, 19).replace('T', ' ');
  }

  /**
   * Convert Date into string, return only date part. It is time zone aware.
   * Format: 'yyyy-mm-dd'
   *
   * @param date
   */
  public static toStringDateOnly(date: Date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().substr(0, 10);
  }

  /**
   * Convert Date into string, return only time part. It is time zone aware.
   * Format: 'yyyy-mm-dd'
   *
   * @param date
   */
  public static toStringTimeOnly(date: Date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().substr(11, 8);
  }

  /**
   * Convert number of days into number of years.
   *
   * By default rounded to 0 decimal places.
   * See DateHelper.DAYS_IN_YEARS for days in years count.
   *
   * @param days
   * @param round
   */
  public static daysToYears(days: number, round: boolean = true) {
    return round ? Math.round(days / DateHelper.DAYS_IN_YEARS) : days / DateHelper.DAYS_IN_YEARS;
  }

  /**
   * Convert number of years into number of days.
   *
   * By default rounded to 0 decimal places.
   * See DateHelper.DAYS_IN_YEARS for days in years count.
   *
   * @param years
   * @param round
   */
  public static yearsToDays(years: number, round: boolean = true) {
    return round ? Math.round(DateHelper.DAYS_IN_YEARS * years) : DateHelper.DAYS_IN_YEARS * years;
  }
}
