<ng-container *transloco="let t">
  <div class="nav-scroller" *ngIf="licenses$ | async as licenses">
    <div class="nav nav-underline">
      <ng-container *ngIf="showSubNav === subNavEnum.RESULTS">
        <a *ngIf="licenses.childrenManagement" class="nav-link" [routerLink]="links.childList()" routerLinkActive="active">
          {{ t('shared/childList') }}
        </a>
        <a [routerLink]="links.groupReportProblematicCategories()" class="nav-link" routerLinkActive="active">
          <isophi-lock-icon
            [show]="!licenses.groupReports && !licenses.sortedGroupReports && !licenses.groupReportsZ && !licenses.sortedGroupReportsZ"
            [small]="true"
            [muted]="!licenses.groupReports && !licenses.sortedGroupReports && !licenses.groupReportsZ && !licenses.sortedGroupReportsZ"
            [ngClass]="{
              'me-3': !licenses.groupReports && !licenses.sortedGroupReports && !licenses.groupReportsZ && !licenses.sortedGroupReportsZ
            }"
          ></isophi-lock-icon>
          <span
            [ngClass]="{
              'text-muted':
                !licenses.groupReports && !licenses.sortedGroupReports && !licenses.groupReportsZ && !licenses.sortedGroupReportsZ
            }"
          >
            {{ t('child/evaluation') }}
          </span>

          <a class="nav-link" [routerLink]="links.groupSortedReport()" style="display: none"></a>
        </a>
        <a *ngIf="licenses.childrenManagement" class="nav-link" [routerLink]="links.groupList()" routerLinkActive="active">
          {{ t('child/groups') }}
        </a>
        <a class="nav-link" [routerLink]="links.childArchive()" routerLinkActive="active">{{ t('child/archives') }}</a>
      </ng-container>
      <ng-container *ngIf="showSubNav === subNavEnum.MATERIALS">
        <a *ngIf="isMaterialData()" class="nav-link" [routerLink]="links.materialsDiagnostic()" routerLinkActive="active">
          {{ t('support/diagonstikMaterials') }}
        </a>
        <a *ngIf="isEducationalData()" class="nav-link" [routerLink]="links.materialsEducational()" routerLinkActive="active">
          {{ t('support/developmentToolsMaterials') }}
        </a>
      </ng-container>
    </div>
  </div>
</ng-container>
