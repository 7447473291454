import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@isophi/core-legacy';

import { RouterLinkFactory } from '../router/router-link.factory';
import { KindergartenService } from '../services/kindergarten.service';

@Injectable()
export class AuthGuard implements CanActivate {
  public constructor(
    private router: Router,
    private routerLinkFactory: RouterLinkFactory,
    private kindergartenService: KindergartenService,
    private authService: AuthService
  ) {}

  public async canActivate(): Promise<boolean> {
    if (!this.authService.loggedUser || !this.kindergartenService.loggedKindergarten) {
      this.router.navigate(this.routerLinkFactory.logout());
      return false;
    }

    return true;
  }
}
