import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, User } from '@isophi/core-legacy';
import { ToastService } from '@isophi/core-ui';
import { TranslocoService } from '@ngneat/transloco';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { RouterLinkFactory } from '../../../../core/router/router-link.factory';
import { UserFormComponent } from '../../../../modules/kindergarten/components/user-form/user-form.component';
import { UserDto } from '../../../../modules/kindergarten/dto/user.dto';
import { TeacherService } from '../../../../modules/kindergarten/services/teacher.service';

@Component({
  selector: 'app-edit-teacher',
  templateUrl: './edit-teacher.component.html',
  styleUrls: ['./edit-teacher.component.scss'],
})
export class EditTeacherComponent implements OnInit {
  @ViewChild(UserFormComponent) public userFormComponent: UserFormComponent;

  public loggedUser: User;

  public userDto$: Observable<UserDto>;

  public teacherId: number;

  public constructor(
    private teacherService: TeacherService,
    private router: Router,
    private route: ActivatedRoute,
    public links: RouterLinkFactory,
    private authService: AuthService,
    private toastr: ToastService,
    private translocoService: TranslocoService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.loggedUser = this.authService.loggedUser;
    this.teacherId = Number.parseInt(this.route.snapshot.paramMap.get('id'), 10);

    this.userDto$ = this.teacherService.getTeacher(this.teacherId).pipe(
      map((user) => UserDto.fromUser(user)),
      catchError(() => {
        this.toastr.error(this.translocoService.translate('kindergarten/editFailed'), this.translocoService.translate('kindergarten/editTeacher'));
        this.router.navigate(this.links.teacherList());
        return EMPTY;
      })
    );
  }

  public onSubmit(userDto: UserDto): void {
    this.teacherService.updateTeacher(userDto, this.teacherId).subscribe({
      next: () => {
        this.toastr.success(this.translocoService.translate('kindergarten/editSuccess'), this.translocoService.translate('kindergarten/editTeacher'));
        this.router.navigate(this.links.teacherList());
      },
      error: (err) => this.userFormComponent.updateFormErrors(err)
    });
  }
}
