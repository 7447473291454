import * as Factory from 'factory.ts';
import * as Faker from 'faker';

import { Child } from '../../models';
import { BaseFactory } from './base.factory';

export interface IChild {
  id: number;
  uuid: string;
  first_name: string;
  last_name: string;
  gender: string;
  birth_date: string;
  revision: number;
  url: string;
}

export const randomChildDataFactory = Factory.Sync.makeFactory<IChild>({
  id: Factory.each((i) => i),
  uuid: Factory.each(() => Faker.datatype.uuid()),
  first_name: Factory.each(() => Faker.name.firstName()),
  last_name: Factory.each(() => Faker.name.lastName()),
  gender: Factory.each(() => Faker.random.arrayElement(['male', 'female'])),
  birth_date: Factory.each(() => {
    const nowYear = new Date().getFullYear();
    const startDate = new Date(nowYear - 6, 0, 1);
    const endDate = new Date(nowYear - 3, 0, 1);
    return Faker.date.between(startDate, endDate).toISOString().substr(0, 10);
  }),
  revision: 0,
  url: Factory.each((i) => `http://example.com/api/v1/children/${i}/`)
});

export const childDataFactory = Factory.Sync.makeFactory<IChild>({
  id: 1,
  uuid: '03d7afde-84ae-4695-a30c-cdeb4b0b57f9',
  first_name: 'John',
  last_name: 'Smith',
  gender: 'male',
  birth_date: Factory.each(() => {
    const nowYear = new Date().getFullYear();
    const birthYear = nowYear - 4;
    return `${birthYear}-05-23`;
  }),
  revision: 0,
  url: 'http://example.com/api/v1/children/1/'
});

export class ChildFactory extends BaseFactory<IChild, Child> {
  constructor() {
    super();
    this.dataFactory = childDataFactory;
    this.randomDataFactory = randomChildDataFactory;
    this.classRef = Child;
  }
}
