import { MenuContract } from "../contracts";
import { MenuTarget } from "./menu-target.model";

export class Menu {
  constructor(
    public links: MenuTarget[],
    public target: MenuTarget
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: MenuContract): Menu {
    return new Menu(
      data.links.map((target) => MenuTarget.deserialize(target)),
      MenuTarget.deserialize(data.target),
    )
  }
}