import { IStorage } from './istorage.storage';

export abstract class BaseStorage implements IStorage {
  public static readonly REMOVE_ON_LOGOUT = 'removeOnLogout';

  /**
   * Saves item into storage.
   *
   * @param key
   * @param value
   * @param removeOnLogout flag if the key should be removed on logout
   */
  setItem(key: string, value: string, removeOnLogout?: boolean) {
    this.saveItem(key, value);

    // Add item to the "remove on logout" array if relevant
    if (removeOnLogout) {
      let removeOnLogoutItems: string[] = [];

      if (this.hasItem(BaseStorage.REMOVE_ON_LOGOUT)) {
        removeOnLogoutItems = JSON.parse(this.getItem(BaseStorage.REMOVE_ON_LOGOUT));
      }

      if (removeOnLogoutItems.indexOf(key) === -1) {
        removeOnLogoutItems.push(key);
      }

      this.saveItem(BaseStorage.REMOVE_ON_LOGOUT, JSON.stringify(removeOnLogoutItems));
    }
  }

  /**
   * Removes all items which should be removed on logout.
   */
  public removeOnLogout(): void {
    let removeOnLogoutItems: string[] = [];

    if (this.hasItem(BaseStorage.REMOVE_ON_LOGOUT)) {
      removeOnLogoutItems = JSON.parse(this.getItem(BaseStorage.REMOVE_ON_LOGOUT));

      for (const item of removeOnLogoutItems) {
        this.removeItem(item);
      }

      this.removeItem(BaseStorage.REMOVE_ON_LOGOUT);
    }
  }

  abstract getItem(key: string): string;

  abstract hasItem(key: string): boolean;

  abstract removeItem(key: string): void;

  abstract clear(): void;

  abstract getIdentifier(): string;

  /**
   * For saving the (key, value) to the storage place - used in setItem.
   *
   * @param key
   * @param value
   */
  protected abstract saveItem(key: string, value: string): void;
}
