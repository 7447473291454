<ng-container *transloco="let t">
  <div class="alert alert-danger" role="alert" *ngIf="formErrors.error">
    {{ formErrors.error }}
  </div>

  <form #teacherForm="ngForm" (ngSubmit)="onSubmitForm()">
    <div class="form-group">
      <label class="fw-bold" for="firstName">{{ t('child/name') }} *</label>
      <input
        [(ngModel)]="userDto.firstName"
        [ngClass]="{ 'form-control form-control-lg': true, 'is-invalid': formErrors.firstNameError !== null }"
        [disabled]="disabled"
        id="firstName"
        name="firstName"
        required
        type="text"
      />
      <div class="invalid-feedback">
        {{ formErrors.firstNameError }}
      </div>
    </div>
    <div class="form-group">
      <label class="fw-bold" for="lastName">{{ t('child/surname') }} *</label>
      <input
        [(ngModel)]="userDto.lastName"
        [ngClass]="{ 'form-control form-control-lg': true, 'is-invalid': formErrors.lastNameError !== null }"
        [disabled]="disabled"
        id="lastName"
        name="lastName"
        required
        type="text"
      />
      <div class="invalid-feedback">
        {{ formErrors.lastNameError }}
      </div>
    </div>
    <div class="form-group">
      <label class="fw-bold" for="email">{{ t('registration/email') }} *</label>
      <input
        [(ngModel)]="userDto.email"
        [ngClass]="{ 'form-control form-control-lg': true, 'is-invalid': formErrors.emailError !== null }"
        [disabled]="disabled"
        email
        id="email"
        name="email"
        required
        type="text"
      />
      <div class="invalid-feedback">
        {{ formErrors.emailError }}
      </div>
    </div>

    <div *ngIf="showPasswordField; else hiddenPassword" class="form-group">
      <label class="fw-bold" for="password" [innerHTML]="t('kindergarten/generatedPassword')"></label>
      <input
        [(ngModel)]="userDto.password"
        [ngClass]="{ 'form-control form-control-lg': true, 'is-invalid': formErrors.passwordError !== null }"
        [disabled]="disabled"
        id="password"
        name="password"
        required
        type="text"
      />
      <p class="mt-4">
        {{ t('kindergarten/passwordWarning') }}
      </p>
      <div class="invalid-feedback">
        {{ formErrors.passwordError }}
      </div>
    </div>

    <ng-template #hiddenPassword>
      <div *ngIf="!selfEdit" class="form-group">
        <!-- Admin of the organisation will see this while editing a teacher. -->
        <label class="fw-bold" for="hiddenPassword"
          >{{ t('kindergarten/hiddenPassword') }}
          <a [routerLink]="forgottenPasswordLink">{{ t('registration/forgottenPassword') }}</a></label
        >
        <input
          [ngClass]="{ 'form-control form-control-lg': true }"
          [disabled]="true"
          id="hiddenPassword"
          name="hiddenPassword"
          [placeholder]="'**************'"
          type="text"
        />
        <p class="mt-4">
          {{ t('kindergarten/hiddenPasswordInfo') }}
        </p>
      </div>
    </ng-template>

    <div class="text-end mt-7">
      <button class="btn btn-outline-dark me-2" type="button" [routerLink]="links.teacherList()">
        {{ t('child/cancel') }}
      </button>

      <button [disabled]="!teacherForm.form.valid || disabled" class="btn btn-secondary" type="submit">
        {{ submitButtonText }}
      </button>
    </div>
  </form>
</ng-container>
