/**
 * Dates to specify license validity.
 *
 * All strings are date in format ISO 8601 with timezones.
 */
export interface LicenseDatesContract {
  /**
   * The date until which license is valid.
   *
   * If null license is unlimited.
   */
  valid_to: string | null;

  /**
   * The date until which expired license is tolerated.
   *
   * If null license toleration is unlimited.
   */
  toleration_to: string | null;

  /**
   * The date from which license is valid.
   *
   * If null license is valid from creation (i.e. license start is unlimited).
   */
  valid_from: string | null;
}
