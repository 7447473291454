import { EvaluationContract } from '.';
import { GroupCategoryResultContract } from '.';
import { GroupChildResultContract } from '.';
import { ProblematicCategoryContract } from '.';

/**
 * Calculated report result for group of children.
 *
 * @field calculated    True/False if report with current settings was calculated,
 *                      if this field is 'null', all other fields are NOT set.
 */
export interface GroupResultContract {
  calculated: boolean;
  test_uuid: string;
  max_score: number;
  percent: number;
  score: number;
  problematic_percent_limit: number;
  child_average_age: number;
  evaluation: EvaluationContract | null;
  category_results: GroupCategoryResultContract[];
  child_results: GroupChildResultContract[];
  problematic_categories: ProblematicCategoryContract[];
}
