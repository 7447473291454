export interface ProductContract {
  id: number;
  title: string;
  codename: string;
  type: string;
  promo_description: string;
  image: string;
  web_link: string;
  is_visible: boolean;
  should_promote: boolean;
  target_type: string;
  order: number;
  color: string;
}
