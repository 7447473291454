import * as Factory from 'factory.ts';
import * as Faker from 'faker';

import { Category } from '../../models';
import { colorFactory } from '../factory.utils';
import { BaseFactory } from './base.factory';

export interface ICategory {
  id: number;
  uuid: string;
  name: string;
  active_icon_url: string;
  inactive_icon_url: string;
  color: string;
}

export const randomCategoryDataFactory = Factory.Sync.makeFactory<ICategory>({
  id: Factory.each((i) => i),
  uuid: Factory.each(() => Faker.datatype.uuid()),
  name: Factory.each(() => Faker.lorem.words(2)),
  active_icon_url: Factory.each((i) => `/media/active_icons/category/${i}.svg`),
  inactive_icon_url: Factory.each((i) => `/media/inactive_icons/category/${i}.svg`),
  color: Factory.each(colorFactory)
});

export const categoryDataFactory = Factory.Sync.makeFactory<ICategory>({
  id: 1,
  uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
  name: 'grafomotorika',
  active_icon_url: '/media/uploaded/tags/active/category/grafomotorika.svg',
  inactive_icon_url: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
  color: '#E0937E'
});

export class CategoryFactory extends BaseFactory<ICategory, Category> {
  public knownList: Array<Category> = [];

  constructor() {
    super();
    this.dataFactory = categoryDataFactory;
    this.randomDataFactory = randomCategoryDataFactory;
    this.classRef = Category;
  }

  /**
   * Create list of categories with real category name and color.
   */
  buildKnownList(): Array<Category> {
    if (this.knownList.length > 0) return this.knownList;

    const data = [
      { id: 1, uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b', name: 'grafomotorika', color: '#E0937E' },
      { id: 2, uuid: '491575d3-bede-4619-9d31-970701538e92', name: 'matematické představy', color: '#F6687C' },
      { id: 3, uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d', name: 'prostorová orientace', color: '#76CC7E' },
      { id: 4, uuid: '91785b30-f2c9-4fba-a37d-9774f5185516', name: 'časová orientace', color: '#46C3C6' },
      { id: 5, uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da', name: 'zrakové vnímání', color: '#AADB6A' },
      { id: 6, uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa', name: 'sluchové vnímání', color: '#F79061' },
      { id: 7, uuid: 'b85c92c1-3f1b-49a2-80af-f92dd1ec6fc6', name: 'řeč a verbální myšlení', color: '#D39FBE' },
      { id: 8, uuid: '950b5973-04c5-4cc7-b11e-6f861919805c', name: 'jemná motorika', color: '#6CB7D8' },
      { id: 9, uuid: 'eedd61ec-80de-441c-8acf-9e6bd79d0bce', name: 'hrubá motorika', color: '#4585C4' },
      { id: 10, uuid: 'e00f6eea-0a6d-41b0-98d3-202e8afb2230', name: 'pozornost', color: '#B2A3E5' },
      { id: 11, uuid: '05a7f36a-2933-4cfb-90b7-8859d938cc98', name: 'sociální chování', color: '#B57878' },
      { id: 12, uuid: '20728a4c-0e11-429b-ad60-0e03b417597f', name: 'sebeobsluha', color: '#9972B2' },
      { id: 13, uuid: '28db1cd2-59ea-459c-a2c8-fba6638d3e96', name: 'pracovní zralost', color: '#F9D00B' },
      { id: 14, uuid: 'cbb212e7-0168-4c05-a38c-db9768610d1e', name: 'algoritmické myšlení', color: '#FFF18F' }
    ];

    this.knownList = data.map((x) => this.build(x));
  }
}
