import { Component, TemplateRef } from '@angular/core';
import { Toast, ToastService } from '../../services/toast.service';

@Component({
  selector: 'isophi-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss']
})
export class ToastsComponent {
  constructor(public toastService: ToastService) {}

  isTemplate(toast: Toast) { return toast.textOrTpl instanceof TemplateRef; }
}
