/**
 * Serialized Child fields.
 *
 * @field url is set when data are coming from server
 * @field synced is set when data are serialized from object by application
 */
export interface IChildData {
  id: number;
  uuid: string;
  birth_date: string;
  first_name: string;
  last_name: string;
  gender: string;
  revision: number;
  url?: string;
  synced?: boolean;
}

export interface ICategoryData {
  id: number;
  uuid: string;
  name: string;
  color: string;
  active_icon_url: string;
  inactive_icon_url: string;
}

export interface IKindergartenData {
  id: number;
  uuid: string;
  code: string;
  law_id: string;
  name: string;
  note: string;
}

export interface ITeacherData {
  id: number;
  is_active: boolean;
  groups: string[];
}

export interface IParentData {
  id: number;
  is_active: boolean;
}

export interface IPasswordData {
  algorithm: string;
  hash: string;
  iterations: number;
  salt: string;
}

/**
 * Serialized User fields.
 *
 * @field teacher is presented on demand
 * @field parent is presented on demand
 * @field password_data is presented on demand
 */
export interface IUserData {
  id: number;
  uuid?: string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  group_string: string;
  teacher?: ITeacherData;
  parent?: IParentData;
  password_data?: IPasswordData;
}
