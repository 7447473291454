import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { PopUpDialogComponent } from './components/pop-up/components/pop-up-dialog/pop-up-dialog.component';
import { CoreUiModule } from '@isophi/core-ui';

import { ButtonWithLoaderComponent } from './components/button-with-loader/button-with-loader.component';
import { PageHeadlineComponent } from './components/page-headline/page-headline.component';
import { RibbonHeaderComponent } from './components/ribbon-header/ribbon-header.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { UpgradeLicensePreviewComponent } from './components/upgrade-license-preview/upgrade-license-preview.component';

const COMPONENTS = [
  PopUpDialogComponent,
  PageHeadlineComponent,
  UpgradeLicensePreviewComponent,
  ButtonWithLoaderComponent,
  RibbonHeaderComponent,
  SectionHeaderComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, FormsModule, NgSelectModule, NgbModule, CoreUiModule],
  exports: [...COMPONENTS, CoreUiModule],
})
export class TeachersSharedModule {}
