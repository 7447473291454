/**
 * Class representing one children group in a kindergarten.
 */
import { GroupContract } from '../contracts';
import { Child } from './child.model';
import * as _ from 'lodash';

export class Group {
  public children: Array<Child> = [];

  public constructor(public id: number | null, public uuid: string | null, public name: string) {}

  public static createByName(name: string): Group {
    return new Group(null, null, name);
  }

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: GroupContract): Group {
    return new Group(data.id, data.uuid, data.name);
  }

  /**
   * Return Group object serialized to basic javascript types.
   */
  public serialize(): GroupContract {
    if (this.uuid) {
      return {
        uuid: this.uuid,
        name: this.name,
        id: this.id
      };
    }

    return {
      name: this.name
    };
  }

  /**
   * Add Child into Group.
   *
   * @param child
   */
  public addChild(child: Child): void {
    const index = this.children.findIndex((ch) => ch.uuid === child.uuid);
    if (index !== -1) return;
    this.children.push(child);
  }

  /**
   * Remove Child from Group.
   *
   * @param child
   */
  public removeChild(child: Child): void {
    const index = this.children.findIndex((ch) => ch.uuid === child.uuid);
    if (index === -1) return;
    this.children.splice(index, 1);
  }

  /**
   * Update Children in Group.
   *
   * @param child
   */
  public updateChildren(childrenData: Child[]) {
    const children: Child[] = _.uniqBy([...this.children, ...childrenData], 'uuid');
    this.children = children.filter((child) => child.groups.some((group) => group.uuid === this.uuid && child.uuid !== null))
  }
}
