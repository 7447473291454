import * as Factory from 'factory.ts';
import * as Faker from 'faker';

import { Parent, Teacher, User } from '../../models';
import { BaseFactory } from './base.factory';
import { IPassword, passwordDataFactory } from './password.factory';

export interface IUser {
  id: number;
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  password_data: IPassword;
  group_string: string;
  teacher: Teacher | null;
  parent: Parent | null;
}

const randomUserDataFactoryBase = Factory.Sync.makeFactory<IUser>({
  id: Factory.each((i) => i),
  email: 'x@example.com',
  username: 'x@example.com',
  first_name: Factory.each(() => Faker.name.firstName()),
  last_name: Factory.each(() => Faker.name.lastName()),
  is_active: true,
  password_data: passwordDataFactory.build(),
  group_string: '',
  teacher: null,
  parent: null
});

export const randomUserDataFactory = randomUserDataFactoryBase
  .withDerivation2(['first_name', 'last_name'], 'email', (firstName, lastName) => {
    const first = firstName.replace(/\s/g, '').toLowerCase();
    const last = lastName.replace(/\s/g, '').toLowerCase();
    return `${first}.${last}@example.com`;
  })
  .withDerivation1(['email'], 'username', (email) => email);

export const userDataFactory = Factory.Sync.makeFactory<IUser>({
  id: 1,
  email: 'john.brown@example.com',
  username: 'john.brown@example.com',
  first_name: 'John',
  last_name: 'Brown',
  is_active: true,
  password_data: passwordDataFactory.build(),
  group_string: '',
  teacher: null,
  parent: null
});

export class UserFactory extends BaseFactory<IUser, User> {
  constructor() {
    super();
    this.dataFactory = userDataFactory;
    this.randomDataFactory = randomUserDataFactory;
    this.classRef = User;
  }
}
