import { BaseStorage } from './base-storage.storage';

export class NullStorage extends BaseStorage {
  /**
   * Load item from storage.
   * It returns null, if key does not exist in the storage.
   *
   * @param key
   */
  public getItem(_key: string): string | null {
    // do nothing
    return null;
  }

  /**
   * Check if storage has item with key.
   *
   * @param key
   */
  public hasItem(_key: string): boolean {
    return false;
  }

  /**
   * Remove item from storage.
   * If key does not exist, this do nothing.
   *
   * @param key
   */
  public removeItem(_key: string): void {
    // do nothing
  }

  /**
   * Clear whole storage.
   */
  public clear(): void {
    // do nothing
  }

  /**
   * Return storage identifier.
   */
  public getIdentifier(): string {
    return 'null-storage';
  }

  /**
   * Null storage does not save anything.
   *
   * @param key
   * @param value
   */
  protected saveItem(_key: string, _value: string): void {
    // do nothing
  }
}
