/**
 * @field short_title and color on request as extra_field
 */
export interface TestContract {
  id: number;
  uuid: string;
  title: string;
  min_age: number;
  max_age: number;
  age: number;
  version: string;
  etag: string;
  short_title?: string;
  color?: string;
  product_codename?: string;
}
