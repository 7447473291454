import { Component, Input, OnChanges } from '@angular/core';
import { childAge } from '../../utils/child.utils';

export enum ChildAgeFormat {
  DECIMAL_FORMAT = 'decimal_format',
  YEAR_MONTH_FORMAT = 'year_month_format',
  YEAR_FORMAT = 'year_format',
}

interface AgeParts {
  years: number;
  months: number;
}

@Component({
  selector: 'isophi-child-age',
  templateUrl: './child-age.component.html',
  styleUrls: ['./child-age.component.scss'],
})
export class ChildAgeComponent implements OnChanges {
  @Input() child!: { birthDate: string | Date | null };

  @Input() finishedAt?: string | Date;

  @Input() format: ChildAgeFormat = ChildAgeFormat.YEAR_FORMAT;

  ageParts: AgeParts = { years: 0, months: 0 };

  ChildAgeFormat = ChildAgeFormat;

  ngOnChanges() {
    const [years, months] = childAge(this.child, this.finishedAt);

    this.ageParts = {
      years,
      months,
    };
  }

  public printAgeInDecimalFormat(): string {
    const age = this.ageParts?.years + this.ageParts?.months / 12;
    return age.toFixed(1).replace('.', ',');
  }
}
