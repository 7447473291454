import * as Factory from 'factory.ts';

import { Teacher } from '../../models';
import { BaseFactory } from './base.factory';

export interface ITeacher {
  id: number;
  is_active: boolean;
  groups: Array<string>;
}

export const randomTeacherDataFactory = Factory.Sync.makeFactory<ITeacher>({
  id: Factory.each((i) => i),
  is_active: true,
  groups: []
});

export const teacherDataFactory = Factory.Sync.makeFactory<ITeacher>({
  id: 1,
  is_active: true,
  groups: []
});

export class TeacherFactory extends BaseFactory<ITeacher, Teacher> {
  constructor() {
    super();
    this.dataFactory = teacherDataFactory;
    this.randomDataFactory = randomTeacherDataFactory;
    this.classRef = Teacher;
  }
}
