/**
 * Class to represent kindergarten.
 */
import { KindergartenContract } from '../../contracts/kindergarten.contract';
import { OrgType } from './org-type.model';

export class Kindergarten {
  constructor(
    public id: number,
    public uuid: string,
    public name: string,
    public lawId: string,
    public code: string,
    public note: string,
    public productCodenames?: string[],
    public orgType?: OrgType[]
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: KindergartenContract): Kindergarten {
    return new Kindergarten(data.id, data.uuid, data.name, data.law_id, data.code, data.note, data.product_codenames, data?.org_type.map((orgType) => OrgType.deserialize(orgType)));
  }

  /**
   * Serialize typescript object into basic javascript types.
   */
  public serialize(): KindergartenContract {
    return {
      id: this.id,
      uuid: this.uuid,
      name: this.name,
      law_id: this.lawId,
      code: this.code,
      note: this.note,
      product_codenames: this.productCodenames,
      org_type: this.orgType.map((orgType) => orgType.serialize())
    };
  }
}
