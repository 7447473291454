/**
 * Features for product type general.
 */
export enum LicenseGeneralFeature {
  CHILDREN_MANAGEMENT = 'children_management',
  GENERAL_SUPPORT = 'general_support',
  QR_CHILD_LOGIN = 'qr_child_login',
  CHILD_TRANS_CODE = 'child_trans_code',
  SHARE_RESULT_PARENT = "share_result_parent"
}
