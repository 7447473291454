import { SystemService } from '../../../services';

export class SystemServiceMock extends SystemService {
  public cordova = false;

  public web = true;

  public isCordova(): boolean {
    return this.cordova;
  }

  public isWeb(): boolean {
    return this.web;
  }
}
