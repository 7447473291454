import { Child } from '@isophi/core-legacy';
import { GroupChildResultContract } from '../contracts';
import { CategoryResult } from './category-result.model';
import { Test } from './test.model';

export class GroupChildResult {
  public categoryResults: Array<CategoryResult> = [];

  public constructor(public test: Test, public child: Child, public maxScore: number, public score: number, public percent: number) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param test
   * @param child
   */
  public static deserialize(data: GroupChildResultContract, test: Test, child: Child): GroupChildResult {
    return new GroupChildResult(test, child, data.max_score, data.score, data.percent);
  }

  public addCategoryResult(categoryResult: CategoryResult): void {
    this.categoryResults.push(categoryResult);
  }
}
