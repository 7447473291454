import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';

import { SubNav, SubnavService } from '../../../core/services/subnav.service';
import { MaterialService, SortType } from '../../dashboard/services/material.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent implements OnDestroy {
  public showSubNav$: Observable<SubNav>;

  private urlsWithSubNavResults: string[] = ['/child', '/reports', '/materials'];

  public urlWithoutBackground = true;

  private _subs: Subscription[] = [];

  constructor(
    private subNavService: SubnavService,
    private router: Router,
    protected materialService: MaterialService
  ) {
    this.showSubNav$ = this.subNavService.showSubNav$;

    this.initMaterialData();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const splittedUrl = event.url.split('/');
        const url = '/' + splittedUrl[1];
        const urlMaterial = '/' + splittedUrl[2];

        if (this.urlsWithSubNavResults.includes(url) && this.subNavService.showSubNavValue !== SubNav.RESULTS && !event.url.includes('child/new')) {
          this.subNavService.toggleSubNav(SubNav.RESULTS);
        } else if (this.urlsWithSubNavResults.includes(urlMaterial) && this.subNavService.showSubNavValue !== SubNav.MATERIALS) {
          this.subNavService.toggleSubNav(SubNav.MATERIALS);
        } else {
          this.subNavService.toggleSubNav(null);
        }

        if (event.url === '/dashboard') {
          this.urlWithoutBackground = false;
        } else {
          this.urlWithoutBackground = true;
        }
      }
    });
  }

  initMaterialData() {
    this._subs.push(
      forkJoin([
        this.materialService.getProducts(SortType.DIAGNOSTIC),
        this.materialService.getDownloadableMaterials(SortType.DIAGNOSTIC),
        this.materialService.getProducts(SortType.EDUCATIONAL),
        this.materialService.getDownloadableMaterials(SortType.EDUCATIONAL)
      ]).subscribe(([diagProducts, diagMaterials, eduProducts, eduMaterials]) => {
        this.materialService.setDiagnosticProductsSubject(diagProducts);
        this.materialService.setDiagnosticMaterialsSubject(diagMaterials);
        this.materialService.setEducationalProductsSubject(eduProducts);
        this.materialService.setEducationalMaterialsSubject(eduMaterials);
      })
    )
  }

  ngOnDestroy(): void {
    this._subs.forEach((_sub) => _sub.unsubscribe());
  }
}
