export class UserFormErrors {
  public constructor(
    public error: string | null = null,
    public emailError: string | null = null,
    public firstNameError: string | null = null,
    public lastNameError: string | null = null,
    public passwordError: string | null = null
  ) {}

  public update(other: UserFormErrors): void {
    this.error = other.error;
    this.emailError = other.emailError;
    this.firstNameError = other.firstNameError;
    this.lastNameError = other.lastNameError;
    this.passwordError = other.passwordError;
  }

  public clearData(): void {
    this.error = null;
    this.emailError = null;
    this.firstNameError = null;
    this.lastNameError = null;
    this.passwordError = null;
  }
}
