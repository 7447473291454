import * as moment from 'moment';

export interface Child {
  firstName: string;
  lastName: string;
  birthDate: string;
}

export const childFullName = (child: Child) => `${child.firstName} ${child.lastName}`.trim();

export const getFirstLetter = (child: Child): string => {
  let firstLetter = child.firstName[0].toLocaleUpperCase();
  const czToEn: { [key: string]: string } = {
    'Á': 'A',
    'Č': 'C',
    'Ď': 'D',
    'É': 'E',
    'Ě': 'E',
    'Í': 'I',
    'Ň': 'N',
    'Ó': 'O',
    'Ř': 'R',
    'Š': 'S',
    'Ť': 'T',
    'Ú': 'U',
    'Ů': 'U',
    'Ý': 'Y',
    'Ž': 'Z'
  }
  if (czToEn[firstLetter]) {
    firstLetter = czToEn[firstLetter];
  }
  return `assets/img/dashboard/profilePics/iSophiR_ProfilePic_${firstLetter}.svg`;
};

export const childAge = (child: { birthDate: string | Date | null }, finishedAt?: string | Date) => {
  const birthDate = moment(child.birthDate);
  let now;

  if (finishedAt) {
    now = moment(finishedAt);
  } else {
    now = moment();
  }

  const years = now.diff(birthDate, 'years');

  const months = now.diff(birthDate, 'months') % 12;

  return [years, months];
};

export const roundAge = (age: number): string => {
  return `${round(age, 1)}`.replace('.', ',');
};

const round = (value: number, precision?: number) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const birthDateExample = (): string => {
  return `10. 3. ${new Date().getFullYear() - 5}`;
}
