import { Injectable } from '@angular/core';
import { AlertType } from '@isophi/core-legacy';

import { PopUpComponent } from '../../pop-up.component';

// Refactor / rename to service
@Injectable({
  providedIn: 'root'
})
export class NoLicensePopUpComponent {
  public constructor(private popUp: PopUpComponent) {}

  public showNoLicensePopUp(featureName?: string): void {
    if (featureName) {
      featureName = 'akci: <i>' + featureName + '</i>';
    } else featureName = 'tuto akci';

    const alertText =
      'Vaše licence nezahrnuje ' +
      featureName +
      ', lze ji ale snadno rozšířit - ' +
      '<a href="https://isophi.cz/kontakt/" target="_blank">spojte se s námi</a>.';
    this.popUp.alert('Nedostatečná licence', alertText, AlertType.WARNING, 'Rozumím');
  }
}
