/**
 * Product types for licenses.
 */
export enum LicenseProductType {
  TEST = 'test',
  TEST_Z = 'dad_test_z',
  APPLICATION = 'application',
  GAME = 'game',
  ANALYTICS = 'analytics',
  GENERAL = 'general',
  SMART = 'smart',
  CIRIL = 'ciril',
  QUESTIONNAIRE = 'questionnaire',
  PARENTS_APP = 'parents_app'
}
