import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService, LicenseGeneralFeature, LicenseProductType, LicenseService, User } from '@isophi/core-legacy';
import { IconService } from '@isophi/core-ui';
import { Menu } from '@isophi/teachers-shared';
import { combineLatest, Observable, Subscription } from 'rxjs';

import { RouterLinkFactory } from '../../../core/router/router-link.factory';
import { MenuService, MenuType } from '../services/menu.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('mainDropdownButton') mainDropdownButton: ElementRef<HTMLElement>;

  @ViewChild('mainDropdown') mainDropdown: ElementRef<HTMLElement>;

  loggedUser: User;

  licenses$: Observable<Record<string, boolean>>;

  menuData$: Observable<Menu[]>;

  loggedUserEditLink: (string | number)[];

  private _subs: Subscription[] = [];

  constructor(
    public icons: IconService,
    public links: RouterLinkFactory,
    private authService: AuthService,
    private licenseService: LicenseService,
    private router: Router,
    private menuService: MenuService
  ) {}

  ngOnInit(): void {
    this.loggedUser = this.authService.loggedUser;
    this.loggedUserEditLink = this.links.editTeacher(this.authService.loggedUser.teacher.id);
    this.menuData$ = this.menuService.getMenu(MenuType.TOP);

    this._subs.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.mainDropdownButton.nativeElement.classList.remove('show');
          this.mainDropdown.nativeElement.classList.remove('show');
        }
      })
    );
    this.initLicenses();
  }

  get username(): string {
    return this.authService.loggedUser?.email;
  }

  /**
   * Init licenses for this component.
   * Based on them some navigation items are shown/hidden.
   */
  private initLicenses(): void {
    this.licenses$ = combineLatest({
      // Check if there are any support materials, videos etc. for the products user has
      generalSupport: this.licenseService.hasAnyProductWithFeature(LicenseProductType.GENERAL, LicenseGeneralFeature.GENERAL_SUPPORT),
    });
  }

  ngOnDestroy(): void {
    this._subs.forEach((_sub) => _sub.unsubscribe());
  }
}
