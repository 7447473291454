import { User } from '@isophi/core-legacy';

/**
 * Class to hold data, when creating/editing user (teacher).
 */
export class UserDto {
  public constructor(
    public firstName: string = '',
    public lastName: string = '',
    public email: string = '',
    public password: string | null = '',
    public active: boolean = true
  ) {}

  public static fromUser(user: User): UserDto {
    return new UserDto(user.firstName, user.lastName, user.email, '', user.teacher.active);
  }

  public generateRandomPassword(length: number = 10): void {
    this.password = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      this.password += characters.charAt(Math.floor(Math.random() * characters.length));
    }
  }

  /**
   * Serialize data.
   */
  public serialize(): any {
    const data: any = {
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email,
      active: this.active
    };
    if (this.password && this.password !== '') data.password = this.password;
    return data;
  }
}
