import { Observable, of } from 'rxjs';

import { Category } from '../../../models';
import { CategoryService } from '../../../services';

export class CategoryServiceMock extends CategoryService {
  public category: Category;

  public categories: Category[];

  public get categories$(): Observable<Category[]> {
    return of(this.categories);
  }

  public getCategory(_uuid: string): Observable<Category> {
    return of(this.category);
  }
}
