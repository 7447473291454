import * as Factory from 'factory.ts';
import * as Faker from 'faker';

import { Kindergarten } from '../../models';
import { BaseFactory } from './base.factory';

export interface IKindergarten {
  id: number;
  uuid: string;
  name: string;
  law_id: string;
  note: string;
}

export const randomKindergartenDataFactory = Factory.Sync.makeFactory<IKindergarten>({
  id: Factory.each((i) => i),
  uuid: Factory.each(() => Faker.datatype.uuid()),
  name: Factory.each(() => Faker.lorem.words(2)),
  law_id: Factory.each(() => Number(Faker.datatype.number({ min: 1000, max: 1999 })).toString(10)),
  note: Factory.each(() => Faker.lorem.sentence())
});

export const kindergartenDataFactory = Factory.Sync.makeFactory<IKindergarten>({
  id: 10,
  uuid: '601ed035-af73-4ec4-9fea-ab6950317a90',
  name: 'iSophi Education',
  law_id: '12345',
  note: 'Testing iSophi kindergarten.'
});

export class KindergartenFactory extends BaseFactory<IKindergarten, Kindergarten> {
  constructor() {
    super();
    this.dataFactory = kindergartenDataFactory;
    this.randomDataFactory = randomKindergartenDataFactory;
    this.classRef = Kindergarten;
  }
}
