<ng-container *transloco="let t">
  <ng-container *ngIf="format === ChildAgeFormat.DECIMAL_FORMAT">{{ printAgeInDecimalFormat() }} {{ t('child/years') }} </ng-container>
  <ng-container *ngIf="[ChildAgeFormat.YEAR_MONTH_FORMAT, ChildAgeFormat.YEAR_FORMAT].includes(format)"
    >{{ ageParts.years }}
    <ng-container *ngIf="ageParts.years === 1">{{ t('child/year') }}</ng-container>
    <ng-container *ngIf="ageParts.years > 1 && ageParts.years <= 4">{{ t('child/twoToFourYears') }}</ng-container>
    <ng-container *ngIf="ageParts.years === 0 || ageParts.years > 4">{{ t('child/years') }}</ng-container>
  </ng-container>

  <ng-container *ngIf="format === ChildAgeFormat.YEAR_MONTH_FORMAT">
    {{ ageParts.months }}
    <ng-container *ngIf="ageParts.months === 1">{{ t('child/month') }}</ng-container>
    <ng-container *ngIf="ageParts.months > 1 && ageParts.months <= 4">{{ t('child/twoToFourMonths') }}</ng-container>
    <ng-container *ngIf="ageParts.months === 0 || ageParts.months > 4">{{ t('child/months') }}</ng-container>
  </ng-container>
</ng-container>
