import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss']
})
export class SectionHeaderComponent implements OnInit {
  @Input() public order: string;

  @Input() public text: string;

  public underlineString: string;

  public normalString: string;

  public ngOnInit(): void {
    this.underlineString = this.text.substring(0, 3);
    this.normalString = this.text.substring(3);
  }
}
