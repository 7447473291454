import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { CoreLegacyModule } from '@isophi/core-legacy';
import { APP_CONFIG, AppConfig } from '@isophi/core-ui';
import * as Sentry from '@sentry/angular-ivy';
import { BASE_PATH } from 'isophi-pdfs-api';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { NgxSpinnerModule } from 'ngx-spinner';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ApiInterceptor } from './core/guards/api.interceptor';
import { InitService } from './core/services/init.service';
import { LayoutModule } from './modules/layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { TranslocoRootModule } from './transloco-root.module';



registerLocaleData(localeCs, 'cs');

export function initApplication(initService: InitService) {
  return () => initService.initApplication();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreLegacyModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    TranslocoRootModule,
    SharedModule.forRoot(),
    AppRoutingModule,
    NgxSpinnerModule,
    HttpClientModule,
    FormsModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga_measurement_id),
    NgxGoogleAnalyticsRouterModule,
    LayoutModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: BASE_PATH, useValue: environment.pdfApiBase },
    { provide: LOCALE_ID, useValue: 'cs' },
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      multi: true,
      deps: [InitService, Sentry.TraceService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: APP_CONFIG,
      useValue: {
        i18n: environment.i18n,
      } as AppConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
