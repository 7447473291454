<div class="modal-header">
  <h5 class="modal-title {{ data.titleClass }}">
    {{ data.title }}
  </h5>
</div>

<div class="modal-body {{ data.bodyClass }}">
  <ng-container *ngIf="!inputValid">
    <ngb-alert type="danger" [dismissible]="false">
      {{ data.validationMessage }}
    </ngb-alert>
  </ng-container>
  <ng-container *ngIf="data.popUpType !== popUpType.PROMPT && data.popUpType !== popUpType.SELECT">
    <ng-container *ngIf="data.alertType !== null">
      <ngb-alert type="{{ data.alertType }}" [dismissible]="false">
        <p [innerHTML]="data.text | safe: 'html'"></p>
        <span class="clear-both"></span>
      </ngb-alert>
    </ng-container>
    <ng-container *ngIf="data.alertType === null">
      <p [innerHTML]="data.text | safe: 'html'"></p>
      <div class="clear-both"></div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="data.popUpType === popUpType.PROMPT">
    <form name="modal-form">
      <div class="form-group">
        <label for="inputValue" class="fw-bold">{{ data.text }}</label>
        <div class="input-group">
          <input
            id="inputValue"
            class="form-control form-control-lg"
            [placeholder]="data.placeholder"
            name="inputName"
            [(ngModel)]="inputValue"
            (input)="inputValid = true"
            autocomplete="off"
            required
          />
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="data.popUpType === popUpType.SELECT">
    <form name="modal-form">
      <div class="form-group">
        <label for="inputValue" class="fw-bold">{{ data.text }}</label>
        <div class="input-group">
          <ng-select
            class="custom-modal-select"
            name="select"
            [items]="data.options"
            [bindLabel]="data.optionLabel"
            [clearable]="false"
            [searchable]="false"
            [(ngModel)]="selectValue"
          ></ng-select>
        </div>
      </div>
    </form>
  </ng-container>
</div>

<div class="modal-footer {{ data.footerClass }}">
  <button
    *ngIf="data.popUpType === popUpType.PROMPT || data.popUpType === popUpType.SELECT"
    class="btn {{ data.cancelButtonClass }} m-0 me-2"
    (click)="closeModal(false)"
  >
    {{ data.cancelText }}
  </button>
  <button
    *ngIf="data.popUpType === popUpType.PROMPT || data.popUpType === popUpType.SELECT"
    class="btn {{ data.okButtonClass }} m-0"
    (click)="okPromptButton()"
  >
    {{ data.okText }}
  </button>
  <button *ngIf="data.popUpType === popUpType.ALERT" class="btn m-0" (click)="closeModal(true)">
    {{ data.okText }}
  </button>
  <button *ngIf="data.popUpType === popUpType.CONFIRM" class="btn {{ data.noButtonClass }} m-0 me-2" (click)="closeModal(false)">
    {{ data.noText }}
  </button>
  <button *ngIf="data.popUpType === popUpType.CONFIRM" class="btn {{ data.yesButtonClass }} m-0" (click)="closeModal(true)">
    {{ data.yesText }}
  </button>
</div>
