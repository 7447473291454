import { ProductContract } from '../contracts';

export class Product {
  public constructor(
    public id: number,
    public title: string,
    public codename: string,
    public type: string,
    public promoDescription: string,
    public image: string,
    public webLink: string,
    public shouldPromote: boolean,
    public targetType: string,
    public isVisible: boolean,
    public order: number,
    public color: string
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: ProductContract): Product {
    return new Product(data.id, data.title, data.codename, data.type, data.promo_description, data.image, data.web_link, data.should_promote, data.target_type, data.is_visible, data.order, data.color);
  }
}
