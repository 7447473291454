import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum SubNav {
  RESULTS = 'results',
  GAMES = 'games',
  MATERIALS = 'materials'
}

@Injectable({
  providedIn: 'root'
})
export class SubnavService {
  private showSubNavSubject = new BehaviorSubject<SubNav>(null);

  public showSubNav$ = this.showSubNavSubject.asObservable();

  public showSubNavValue = this.showSubNavSubject.value;

  public toggleSubNav(value: SubNav): void {
    this.showSubNavSubject.next(value)
  }
}
