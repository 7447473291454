import * as Factory from 'factory.ts';

import { Password } from '../../models';
import { BaseFactory } from './base.factory';

export interface IPassword {
  algorithm: string;
  iterations: number;
  salt: string;
  hash: string;
}

export const passwordDataFactory = Factory.Sync.makeFactory<IPassword>({
  algorithm: 'pbkdf2_sha256',
  iterations: 180000,
  salt: 'gmilyJmxRWvV',
  hash: '7IbLbmNg15Yy9AG83Z48PDaEHKc05jTln9FKbrZ0InM='
});

export class PasswordFactory extends BaseFactory<IPassword, Password> {
  constructor() {
    super();
    this.dataFactory = passwordDataFactory;
    this.randomDataFactory = passwordDataFactory;
    this.classRef = Password;
  }

  public getRawPassword(): string {
    return 'password';
  }
}
