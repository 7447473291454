import { Category, Child } from '@isophi/core-legacy';
import { SortedReportDataContract } from '../contracts';
import { EvaluationSorted } from './evaluation-sorted.model';
import { SortedReportChildResult } from './sorted-report-child-result.model';

export class SortedReportChildrenResult {
  constructor(
    public calculated: boolean,
    public categories: Category[] | null,
    public evaluations: EvaluationSorted[] | null,
    public childrenResults: SortedReportChildResult[] | null
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param children
   * @param categories
   */
  public static deserialize(data: SortedReportDataContract, children: Child[], categories: Category[]): SortedReportChildrenResult {
    const categories1 = data.categories.map((c) => categories.find((ca) => ca.uuid === c));
    const evaluations = data.evaluations.map((e) => EvaluationSorted.deserialize(e));

    return new SortedReportChildrenResult(
      data.calculated,
      categories1,
      evaluations,
      data.children_results.map((childResult) =>
        SortedReportChildResult.deserialize(
          childResult,
          children.find((ch) => ch.uuid === childResult.child_uuid),
          categories,
          evaluations
        )
      )
    );
  }
}
