import { ReportContract } from '../contracts';

/**
 * One report, which can be calculated by server.
 */
export class Report {
  public constructor(public id: number, public title: string, public keyword: string, public type: string) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: ReportContract): Report {
    return new Report(data.id, data.title, data.keyword, data.type);
  }

  /**
   * Return Report object serialized to basic javascript types.
   */
  public serialize(): ReportContract {
    return {
      id: this.id,
      title: this.title,
      keyword: this.keyword,
      type: this.type,
    };
  }
}
