/**
 * Children group
 *
 * @field id only when data come from server
 * @field uuid when data come from server
 */
export interface GroupContract {
  id?: number;
  uuid?: string;
  name: string;
}
