import { MaterialProductContract } from "../contracts";

export class MaterialProduct {
  constructor(
    public codename: string,
    public id: number,
    public name: string,
    public title: string,
    public type: string,
    public color: string,
    public shortTitle: string
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   */
  public static deserialize(data: MaterialProductContract): MaterialProduct {
    return new MaterialProduct(
      data.codename,
      data.id,
      data.name,
      data.title,
      data.type,
      data.color,
      data.short_title
    )
  }
}