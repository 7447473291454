import { Category } from '@isophi/core-legacy';
import { DocumentContract } from '../contracts';

export class Document {
  constructor(
    public uuid: string,
    public title: string,
    public category: Category,
    public file: string,
    public previewImage: string
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param category
   */
  public static deserialize(
    data: DocumentContract,
    categories: Category[]
  ) {
    return new Document(data.uuid, data.title, categories.find((category) => category.uuid === data.category), data.file, data.preview_image);
  }
}