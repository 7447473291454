import { GroupCategoryResultContract } from '.';

/**
 * Result in one unit (test/questionnaire/...)
 */
export interface GroupChildResultContract {
  child_uuid: string;
  max_score: number;
  percent: number;
  score: number;
  category_results: GroupCategoryResultContract[];
}
