import { IndividualReportSettingsContract } from '../contracts';
import { Group } from '.';
import { ReportSettings } from '.';

export class IndividualReportSettings implements ReportSettings {
  public constructor(public dataSetUuid: string) {}

  /**
   * Deserialize JSON to typescript object.
   */
  public static deserialize(data: IndividualReportSettingsContract): IndividualReportSettings {
    return new IndividualReportSettings(data.data_set_uuid);
  }

  /**
   * todo(doubravskytomas): improve parent class
   * todo(doubravskytomas): getGroups method should not be required in individual reports
   */
  public getGroups(): Array<Group> | null {
    return [];
  }

  /**
   * Return Report object serialized to basic javascript types.
   */
  public serialize(): IndividualReportSettingsContract {
    return {
      data_set_uuid: this.dataSetUuid,
    };
  }

  /**
   * Return Report object serialized to basic javascript types without any nested fields.
   */
  public apiSerialize(): IndividualReportSettingsContract {
    return {
      data_set_uuid: this.dataSetUuid,
    };
  }
}
