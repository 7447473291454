import { ProductGroupContract } from '../contracts';
import { MenuTarget } from './menu-target.model';

export class ProductGroup {
  public constructor(
    public name: string,
    public title: string,
    public logo: string,
    public shortLogo: string,
    public noResultsHint: string,
    public noLicenseLink: string,
    public hasResults: boolean,
    public hasProduct: boolean,
    public promoDescription: string,
    public showInFilter: boolean,
    public noResultsTarget: MenuTarget,
    public order: number
  ) {}

  /**
   * Deserialize JSON to typescript object.
   *
   * @param data
   * @param orderData
   *
   */
  public static deserialize(data: ProductGroupContract, orderData?: {
    dad_test: number;
    dad_test_z: number;
    questionnaire: number;
    game: number;
    smart: number;
  }): ProductGroup {
    return new ProductGroup(data.name, data.title, data.logo, data.short_logo, data.no_results_hint, data.no_license_link, data.has_results, data.has_product, data.promo_description, data.show_in_filter, MenuTarget.deserialize(data.no_results_target), orderData && orderData[data.name]);
  }
}
