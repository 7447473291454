/**
 * Service to manage categories.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CategoryContract, ListResponseContract } from '../../contracts';
import { Category } from '../../models';
import { AuthService } from '../core/auth.service';
import { HttpService } from '../core/http.service';
import { IsophiCoreService } from '../core/isophi-core.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private authService: AuthService,
    private isophiCoreService: IsophiCoreService
  ) {}

  public get categories$(): Observable<Category[]> {
    const url = `${this.isophiCoreService.appApi}/categories/`;
    const options = this.httpService.createRequestOptions(this.authService.accessToken);

    return this.http
      .get<ListResponseContract<CategoryContract>>(url, options)
      .pipe(map((response) => response.results.map((categoryData) => Category.deserialize(categoryData))));
  }

  public getCategory(uuid: string): Observable<Category> {
    const url = `${this.isophiCoreService.appApi}/categories/${uuid}/`;
    const options = this.httpService.createRequestOptions(this.authService.accessToken);

    return this.http.get<CategoryContract>(url, options).pipe(map((categoryData) => Category.deserialize(categoryData)));
  }
}
