import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, shareReplay, switchMap } from 'rxjs/operators';
import { BriefSubjectDetailContract } from '../../contracts/brief-subject-detail.contract';
import { LicenseDatesContract } from '../../contracts/license-dates.contract';
import { SubjectLicensesContract } from '../../contracts/subject-licenses.contract';
import { LicenseDataSource } from '../../enums/license-data-source.enum';
import { LicenseProductType } from '../../enums/license-product-type.enum';
import { IsophiException } from '../../exceptions/isophi.exception';
import { AuthService } from './auth.service';
import { HttpService } from './http.service';
import { IsophiCoreService } from './isophi-core.service';

/**
 * Manage licenses in FE apps.
 *
 * It communicates with BE license server and checks client licenses.
 */
@Injectable({
  providedIn: 'root',
})
export class LicenseService {
  private static readonly CACHE_TIME_IN_HOURS = 24;

  /**
   * Set source of license data.
   */
  public licenseDataSource: LicenseDataSource = LicenseDataSource.API;

  /**
   * Subject (kindergarten/user/...) UUID.
   * All license requests are associated with this subject.
   *
   * When data source is LOCAL_DATA, you don't have to set this value.
   * But library client has to ensure that passed license data are associated
   * with correct subject.
   */
  public licenseSubjectUuid: string | null = null;

  /**
   * License data passed by library client.
   * This field is used when licenseDataSource == LOCAL_DATA
   *
   * @private
   */
  public data: SubjectLicensesContract | null = null;

  /**
   * Cached data from License service response.
   * This field is used when licenseDataSource == API
   *
   * Data are cached for CACHE_TIME_IN_HOURS h, after that are refreshed.
   *
   * @private
   */
  private cache$: Observable<SubjectLicensesContract> | null = null;

  /**
   * Observable that triggers licenses to reload when emitted
   */
  private licensesReload$ = new BehaviorSubject<void>(null);

  private cacheFilledDate: Date | null = null;

  constructor(
    private httpService: HttpService,
    private httpClient: HttpClient,
    private isophiCoreService: IsophiCoreService,
    private authService: AuthService
  ) {}

  /**
   * Return licenses data in server format.
   *
   * @private
   */
  private get licenseData(): Observable<SubjectLicensesContract> {
    if (this.licenseDataSource === LicenseDataSource.LOCAL_DATA) return of(this.data);

    if (this.isCacheInvalid()) this.initData();
    return this.cache$;
  }

  /**
   * Check that subject has product with valid specified feature.
   *
   * @param productCodename
   * @param featureCodename
   */
  public hasProductWithFeature(productCodename: string, featureCodename: string): Observable<boolean> {
    return this.licenseData.pipe(
      map((data) => {
        const licenseDates = data.licenses?.[productCodename]?.[featureCodename];
        if (licenseDates === undefined) return false;
        return this.isLicenseValidNow(licenseDates);
      })
    );
  }

  /**
   * Check that subject has product with any feature.
   *
   * @param productCodename
   * @param anyFeatureActive - True if at least one feature has to be active.
   *    False if all features can be expired.
   *    Default value is true.
   */
  public hasProduct(productCodename: string, anyFeatureActive: boolean = true): Observable<boolean> {
    return this.licenseData.pipe(
      map((data) => {
        const product = data.licenses?.[productCodename];
        if (product === undefined) return false;
        if (!anyFeatureActive) return true;
        for (const feature of Object.keys(product)) {
          const licenseDates = product[feature];
          if (this.isLicenseValidNow(licenseDates)) return true;
        }
        return false;
      })
    );
  }

  /**
   * Check that subject has any product of specified type.
   *
   * Subject has any product of specified type,
   * if there is at least one product of that type
   * with at least one feature associated with the subject
   * (optionally checked if found feature is valid).
   *
   * @param productType
   * @param anyFeatureActive     True/False if checked feature has to be valid. Default value is true.
   */
  public hasAnyProduct(productType: LicenseProductType, anyFeatureActive: boolean = true): Observable<boolean> {
    return this.getProductsByType(productType).pipe(
      mergeMap((productCodenames) => {
        if (productCodenames.length === 0) return of([false]); // No products => resolve to false
        return combineLatest(productCodenames.map((productCodename) => this.hasProduct(productCodename, anyFeatureActive)));
      }),
      map((productChecks) => productChecks.some((v) => v))
    );
  }

  /**
   * Check that subject has all products with any feature.
   *
   * @param productCodenames
   * @param anyFeatureActive - True if at least one feature for every product has to be active.
   *    False if all features for every product can be expired.
   *    Default value is true.
   */
  public hasProducts(productCodenames: string[], anyFeatureActive: boolean = true): Observable<boolean> {
    return combineLatest(productCodenames.map((productCodename) => this.hasProduct(productCodename, anyFeatureActive))).pipe(
      map((productChecks) => productChecks.every((v) => v))
    );
  }

  /**
   * Check that subject has multiple products (all of them) with specified feature.
   *
   * @param productCodenames
   * @param featureCodename
   * @param featureActive     True/False if checked feature has to be valid. Default value is true.
   */
  public hasProductsWithFeature(productCodenames: string[], featureCodename: string, featureActive: boolean = true): Observable<boolean> {
    return this.licenseData.pipe(
      map((data) => {
        for (const productCodename of productCodenames) {
          const licenseDates = data.licenses?.[productCodename]?.[featureCodename];
          if (licenseDates === undefined) return false;
          if (featureActive && !this.isLicenseValidNow(licenseDates)) return false;
        }
        return true;
      })
    );
  }

  /**
   * Check that subject has any product of specified type with specified feature.
   *
   * @param productType
   * @param featureCodename
   * @param featureActive     True/False if checked feature has to be valid. Default value is true.
   */
  public hasAnyProductWithFeature(
    productType: LicenseProductType,
    featureCodename: string,
    featureActive: boolean = true
  ): Observable<boolean> {
    return combineLatest({
      products: this.getProductsByType(productType),
      licenses: this.licenseData.pipe(map((data) => data.licenses)),
    }).pipe(
      map((data) => {
        for (const productCodename of data.products) {
          const licenseDates = data.licenses?.[productCodename]?.[featureCodename];
          if (licenseDates === undefined) continue;
          if (featureActive && !this.isLicenseValidNow(licenseDates)) continue;
          return true;
        }
        return false;
      })
    );
  }

  /**
   * Return list of subject's products by product type.
   *
   * @param productType
   */
  public getProductsByType(productType: LicenseProductType): Observable<string[]> {
    return this.licenseData.pipe(
      map((data) => {
        const products = data.product_types?.[productType];
        if (products === undefined) return [];
        return products;
      })
    );
  }

  public getBriefSubjectDetail(): Observable<BriefSubjectDetailContract> {
    const url = `${this.isophiCoreService.licenseApi}/license-subjects/${this.licenseSubjectUuid}/brief-detail/`;
    const options = this.httpService.createRequestOptions(this.authService.accessToken);
    return this.httpClient.get<BriefSubjectDetailContract>(url, options);
  }

  public applyCode(code: string): Observable<void> {
    const url = `${this.isophiCoreService.licenseApi}/activation-codes/apply/`;
    const options = this.httpService.createRequestOptions(this.authService.accessToken);
    const data = { activation_code: code };
    return this.httpClient.post<void>(url, data, options);
  }

  public reloadLicenses() {
    this.licensesReload$.next();
  }

  public clear() {
    this.cache$ = null;
  }

  public callLicensesApi(childUuid: string): Observable<SubjectLicensesContract> {
    if (childUuid === null) {
      throw new IsophiException('You have to set "licenseSubjectUuid" to use license service.');
    }

    const url = `${this.isophiCoreService.licenseApi}/licenses/${childUuid}/`;
    const options = this.httpService.createRequestOptions(this.authService.accessToken);
    return this.httpClient.get<SubjectLicensesContract>(url, options).pipe(
      shareReplay(1),
      catchError((error) => {
        console.error(error);
        throw new Error('Unable to download communication license data.');
      })
    );
  }

  /**
   * Check if cache is invalid.
   *
   * Cache is invalid before first data download or when data are CACHE_TIME_IN_HOURS hours old.
   *
   * @private
   */
  private isCacheInvalid(): boolean {
    const now = new Date();
    const cacheValidTo = new Date(this.cacheFilledDate);
    cacheValidTo.setHours(cacheValidTo.getHours() + LicenseService.CACHE_TIME_IN_HOURS);
    return this.cache$ === null || cacheValidTo < now;
  }

  /**
   * Init cache observable.
   *
   * @private
   */
  private initData() {
    if (this.licenseSubjectUuid === null) {
      throw new IsophiException('You have to set "licenseSubjectUuid" to use license service.');
    }

    const url = `${this.isophiCoreService.licenseApi}/licenses/${this.licenseSubjectUuid}/`;
    const options = this.httpService.createRequestOptions(this.authService.accessToken);
    this.cacheFilledDate = new Date();

    if (!this.cache$) {
      this.cache$ = merge(of(0), this.licensesReload$).pipe(
        switchMap(() => this.httpClient.get<SubjectLicensesContract>(url, options)),
        shareReplay(1),
        catchError((error) => {
          console.error(error);
          throw new Error('Unable to download license data.');
        })
      );
    } else {
      this.reloadLicenses();
    }
  }

  /**
   * Check if one license dates are valid and license is active.
   *
   * licenseDates.validTo is not check in this method,
   * because for license validity we do not
   * distinguish between valid license and license in toleration.
   *
   * @param licenseDates
   * @private
   */
  private isLicenseValidNow(licenseDates: LicenseDatesContract): boolean {
    const now = new Date();

    if (licenseDates.valid_from !== null) {
      const validFrom = new Date(licenseDates.valid_from);
      if (validFrom > now) return false;
    }

    if (licenseDates.valid_to !== null) {
      const validTo = new Date(licenseDates.valid_to);
      if (validTo >= now) return true;
    }

    if (licenseDates.toleration_to !== null) {
      const tolerationTo = new Date(licenseDates.toleration_to);
      return tolerationTo >= now;
    }

    return true;
  }
}
