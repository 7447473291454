import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiTemplateModule } from '@isophi/ui-template';

import { SharedModule } from '../../shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { MenuComponent } from './menu/menu.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SubnavComponent } from './subnav/subnav.component';

@NgModule({
  imports: [CommonModule, SharedModule, UiTemplateModule],
  declarations: [LayoutComponent, FooterComponent, HeaderComponent, MenuComponent, NavigationComponent, SubnavComponent, LayoutComponent],
})
export class LayoutModule {}
