import { BaseStorage } from './base-storage.storage';

export class SessionStorage extends BaseStorage {
  /**
   * Load item from storage.
   * It returns null, if key does not exist in the storage.
   *
   * @param key
   */
  public getItem(key: string): string | null {
    return window.sessionStorage.getItem(key);
  }

  /**
   * Check if storage has item with key.
   *
   * @param key
   */
  public hasItem(key: string): boolean {
    return window.sessionStorage.getItem(key) !== null;
  }

  /**
   * Remove item from storage.
   * If key does not exist, this do nothing.
   *
   * @param key
   */
  public removeItem(key: string): void {
    window.sessionStorage.removeItem(key);
  }

  /**
   * Clear whole storage.
   */
  public clear(): void {
    window.sessionStorage.clear();
  }

  /**
   * Return storage identifier.
   */
  public getIdentifier(): string {
    return 'session-storage';
  }

  /**
   * For saving the (key, value) to the storage place - used in setItem.
   *
   * @param key
   * @param value
   */
  protected saveItem(key: string, value: string): void {
    window.sessionStorage.setItem(key, value);
  }
}
