<div class="d-flex align-items-center mt-3" *ngIf="category" [class]="theme">
  <isophi-hexagon *ngIf="iconUrl" [iconUrl]="iconUrl"></isophi-hexagon>
  <div class="no-icon" *ngIf="!iconUrl"></div>
  <div class="me-3 category-header">
    <p class="category-name">
      {{ category.category.name }}
    </p>
    <div class="line"><hr /></div>
  </div>

  <div class="row no-gutters" *ngIf="comparison; else show" [style]="{ width: '72%' }">
    <ng-container *ngFor="let i of [1, 2]; let index = index">
      <div class="col-9 progress progress-comparison round-right" [ngClass]="{ opacity: index === 0 }">
        <div
          [ngStyle]="{
            width: math.round(category.percent) + '%',
            'background-color': category.evaluation.backgroundColor
          }"
          aria-valuemin="0"
          class="progress-bar round-right"
          role="progressbar"
        ></div>
        <ng-container *ngFor="let i of [].constructor(+category.maxScore); let index = index">
          <div
            *ngIf="range && range.includes(index); else dotted"
            class="vertical-lines"
            [ngStyle]="{
              left: calcDistance(index),
              borderColor: 'rgba(164, 164, 164,' + calcBorderOpacity(index, +category.percent, +category.maxScore) + ')'
            }"
          ></div>
          <ng-template #dotted>
            <div
              *ngIf="index > 0"
              class="dotted-line"
              [ngStyle]="{
                left: calcDistance(index),
                borderColor: 'rgba(144, 141, 141,' + calcBorderOpacity(index, +category.percent, +category.maxScore) + ')'
              }"
            ></div>
          </ng-template>
        </ng-container>
      </div>
      <div class="col-3 d-flex d-flex justify-content-between align-items-center" [ngClass]="{ opacity: index === 0 }">
        <p class="percent">
          {{ category.score | number: '1.0-0' }} /
          {{ category.maxScore | number: '1.0-0' }}
        </p>
        <ng-container *ngIf="index === 1">
          <div class="icon equal-color">
            <!-- <img class="img-fluid" src="assets/img/equals.png" alt="icon" /> -->
            <span>=</span>
            {{ score }}
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <ng-template #show>
    <div class="progress round-right w-50">
      <div
        [ngStyle]="{
          width: math.round(category.percent) + '%',
          'background-color': category.evaluation.backgroundColor
        }"
        aria-valuemin="0"
        class="progress-bar round-right"
        role="progressbar"
      ></div>
      <ng-container *ngIf="showPoints">
        <ng-container *ngFor="let i of [].constructor(+category.maxScore); let index = index">
          <div
            *ngIf="range && range.includes(index); else dotted"
            class="vertical-lines"
            [ngStyle]="{
              left: calcDistance(index),
              borderColor: 'rgba(164, 164, 164,' + calcBorderOpacity(index, +category.percent, +category.maxScore) + ')'
            }"
          ></div>
          <ng-template #dotted>
            <div
              *ngIf="index > 0"
              class="dotted-line"
              [ngStyle]="{
                left: calcDistance(index),
                borderColor: 'rgba(144, 141, 141,' + calcBorderOpacity(index, +category.percent, +category.maxScore) + ')'
              }"
            ></div>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>
    <p class="percent" *ngIf="showPercent; else showScore">{{ scorePercent(+category.percent) }}%</p>

    <ng-template #showScore>
      <p class="percent">
        {{ category.score | number: '1.0-0' }} /
        {{ category.maxScore | number: '1.0-0' }}
      </p>
    </ng-template>
  </ng-template>
</div>
