/**
 * Result in one psychological category.
 */
export interface IndividualCategoryResultContract {
  category_uuid: string;
  max_score: number;
  percent: number;
  score: number;
  order: number;
  evaluation_title: string;
  evaluation_code: number;
  evaluation_text_color: string;
  evaluation_background_color: string;
}
