/**
 * Service to check system of application.
 *
 * Now it can check if app is running in cordova or in browser.
 */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  /**
   * Check if application is running in cordova.
   */
  public isCordova(): boolean {
    return !!(window as any).cordova;
  }

  /**
   * Check if application is running in web browser.
   */
  public isWeb(): boolean {
    return !this.isCordova();
  }

  /**
   * Return device UUID.
   *
   * It returns:
   * - browser = browser userAgent
   * - HW device = device UUID
   *
   * !!! To work on HW devices it requires 'cordova-plugin-device' installed.
   */
  public getDeviceUuid(): string {
    const device = (window as any)?.device;
    if (device && typeof device.uuid !== undefined) {
      return '' + device.uuid;
    } else {
      return navigator.userAgent;
    }
  }
}
