import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AppConfig, APP_CONFIG } from '../../utils/app.config';

@Component({
  selector: 'isophi-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagePickerComponent {
  constructor(public i18n: TranslocoService, @Inject(APP_CONFIG) public appConfig: AppConfig) {}
}
